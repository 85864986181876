import React from 'react';
import { Project } from '../types';
import '../styles/BusinessOverview.css';

export interface BusinessOverviewProps {
  projects: Project[];
}

export const BusinessOverview: React.FC<BusinessOverviewProps> = ({ projects }) => {
  const calculateTotalRevenue = () => {
    return projects.reduce((total, project) => total + project.selling_price, 0);
  };

  const calculateTotalCost = () => {
    return projects.reduce((total, project) => total + (project.material_cost || 0), 0);
  };

  const calculateTotalProfit = () => {
    return calculateTotalRevenue() - calculateTotalCost();
  };

  const calculateCompletedProjects = () => {
    return projects.filter(project => project.status === 'completed').length;
  };

  const calculatePendingProjects = () => {
    return projects.filter(project => project.status === 'pending').length;
  };

  const calculatePrintingProjects = () => {
    return projects.filter(project => project.status === 'printing').length;
  };

  return (
    <div className="business-overview">
      <div className="overview-card">
        <h3>Revenue</h3>
        <p>${calculateTotalRevenue().toFixed(2)}</p>
      </div>
      <div className="overview-card">
        <h3>Cost</h3>
        <p>${calculateTotalCost().toFixed(2)}</p>
      </div>
      <div className="overview-card">
        <h3>Profit</h3>
        <p>${calculateTotalProfit().toFixed(2)}</p>
      </div>
      <div className="overview-card">
        <h3>Completed</h3>
        <p>{calculateCompletedProjects()}</p>
      </div>
      <div className="overview-card">
        <h3>Pending</h3>
        <p>{calculatePendingProjects()}</p>
      </div>
      <div className="overview-card">
        <h3>Printing</h3>
        <p>{calculatePrintingProjects()}</p>
      </div>
    </div>
  );
};
