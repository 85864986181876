/**
 * Authentication Hook
 * Manages authentication state and provides authentication-related functions
 */
import { useState, useEffect, useCallback } from 'react';
import { User, AuthError } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import { createError } from '../utils/errorHandling';

interface UserProfile {
  id: string;
  username: string;
  email: string;
  created_at: string;
}

interface AuthState {
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
}

interface SignUpData {
  email: string;
  password: string;
  username: string;
}

interface SignInData {
  email: string;
  password: string;
}

// Get the site URL for auth redirects
const getSiteURL = () => {
  // In production, prioritize the custom domain
  if (import.meta.env.PROD) {
    return 'https://www.printingtracker.com';
  }
  // In development, use localhost
  return 'http://localhost:3000';
};

export function useAuth() {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    profile: null,
    loading: true,
  });

  // Fetch user profile from profiles table
  const fetchProfile = useCallback(async (user: User) => {
    try {
      console.log('Fetching profile for user:', user.id);
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error fetching profile:', error);
        throw createError(error);
      }

      console.log('Profile fetched successfully:', {
        userId: data.id,
        username: data.username,
        timestamp: new Date().toISOString()
      });

      return data;
    } catch (error) {
      console.error('Error in fetchProfile:', error);
      return null;
    }
  }, []);

  // Initialize auth state
  const initAuth = useCallback(async () => {
    try {
      console.log('Initializing auth state...');
      // Get current session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError) {
        console.error('Error getting session:', sessionError);
        throw createError(sessionError);
      }

      console.log('Session state:', {
        hasSession: !!session,
        userId: session?.user?.id,
        timestamp: new Date().toISOString()
      });
      
      if (session?.user) {
        console.log('Found existing session for user:', session.user.id);
        const profile = await fetchProfile(session.user);
        setAuthState({
          user: session.user,
          profile,
          loading: false,
        });
      } else {
        console.log('No existing session found');
        setAuthState(prev => ({ ...prev, loading: false }));
      }
    } catch (error) {
      console.error('Error initializing auth:', error);
      setAuthState(prev => ({ ...prev, loading: false }));
    }
  }, [fetchProfile]);

  // Set up auth state change listener
  useEffect(() => {
    console.log('Setting up auth state listener...');
    
    initAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', { 
        event, 
        userId: session?.user?.id,
        timestamp: new Date().toISOString()
      });
      
      if (session?.user) {
        const profile = await fetchProfile(session.user);
        setAuthState({
          user: session.user,
          profile,
          loading: false,
        });
      } else {
        setAuthState({
          user: null,
          profile: null,
          loading: false,
        });
      }
    });

    return () => {
      console.log('Cleaning up auth state listener');
      subscription.unsubscribe();
    };
  }, [fetchProfile, initAuth]);

  // Sign up new user
  const signUp = async ({ email, password, username }: SignUpData) => {
    try {
      console.log('Attempting sign up for:', email);
      const siteURL = getSiteURL();
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username // Pass username as metadata
          },
          emailRedirectTo: `${siteURL}/auth/callback`
        }
      });

      if (authError) throw createError(authError);
      if (!authData.user) throw createError('No user data returned from sign up');

      console.log('Sign up successful for:', email);
      return { error: null };
    } catch (error) {
      console.error('Error signing up:', error);
      return { error: error as AuthError };
    }
  };

  // Sign in existing user
  const signIn = async ({ email, password }: SignInData) => {
    try {
      console.log('Attempting sign in for:', email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw createError(error);

      console.log('Sign in successful:', {
        userId: data.user?.id,
        timestamp: new Date().toISOString()
      });

      return { error: null };
    } catch (error) {
      console.error('Error signing in:', error);
      return { error: error as AuthError };
    }
  };

  // Reset password
  const resetPassword = async (email: string) => {
    try {
      const siteURL = getSiteURL();
      console.log('Sending password reset email to:', email);
      
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${siteURL}/auth/reset-password/callback`
      });

      if (error) throw createError(error);
      console.log('Password reset email sent to:', email);
      return { error: null };
    } catch (error) {
      console.error('Error resetting password:', error);
      return { error: error as AuthError };
    }
  };

  // Sign out
  const signOut = async () => {
    try {
      console.log('Signing out user:', authState.user?.id);
      const { error } = await supabase.auth.signOut();
      if (error) throw createError(error);
      console.log('Sign out successful');
      return { error: null };
    } catch (error) {
      console.error('Error signing out:', error);
      return { error: error as AuthError };
    }
  };

  // Update profile
  const updateProfile = async (updates: Partial<UserProfile>) => {
    try {
      if (!authState.user) throw createError('No user logged in');
      console.log('Updating profile for user:', authState.user.id);

      const { error } = await supabase
        .from('profiles')
        .update(updates)
        .eq('id', authState.user.id);

      if (error) throw createError(error);

      // Refresh profile data
      const profile = await fetchProfile(authState.user);
      setAuthState(prev => ({ ...prev, profile }));
      
      console.log('Profile updated successfully');
      return { error: null };
    } catch (error) {
      console.error('Error updating profile:', error);
      return { error: error as AuthError };
    }
  };

  return {
    user: authState.user,
    profile: authState.profile,
    loading: authState.loading,
    signUp,
    signIn,
    signOut,
    resetPassword,
    updateProfile,
  };
}
