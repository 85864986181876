/**
 * useAuthContext Hook
 * Custom hook for accessing the authentication context
 */
import { useContext } from 'react';
import { AuthContext, AuthContextValue } from '../contexts/auth-context';

/**
 * useAuthContext Hook
 * Provides access to the authentication context
 * @throws {Error} If used outside of AuthProvider
 * @returns {AuthContextValue} The authentication context value
 */
export function useAuthContext(): AuthContextValue {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
}
