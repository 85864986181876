import { useState, useMemo } from 'react';
import { Project, PrinterConfig } from '../../types';
import { usePrinters } from '../../hooks/usePrinters';
import { SortField, SortDirection, ProjectGroup } from './types';

export function useProjectsTable(projects: Project[]) {
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [filter, setFilter] = useState('');
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [collapsedGroups, setCollapsedGroups] = useState<Set<number>>(new Set());
  const [showToast, setShowToast] = useState(false);
  const { printers } = usePrinters();

  const getDisplayId = (filamentId: string): string => {
    return filamentId ? filamentId.toString().padStart(2, '0') : '00';
  };

  const getPrinterFromString = (printerString: string): PrinterConfig | null => {
    const match = printerString.match(/Printer (\d+)/);
    if (match) {
      const printerId = parseInt(match[1]);
      const printer = printers.find(p => p.id === printerId);
      if (printer) {
        return {
          id: printer.id,
          name: printer.name,
          model: printer.model,
          outOfOrder: printer.out_of_order || false
        };
      }
    }

    const printerByName = printers.find(p => p.name === printerString);
    if (printerByName) {
      return {
        id: printerByName.id,
        name: printerByName.name,
        model: printerByName.model,
        outOfOrder: printerByName.out_of_order || false
      };
    }

    return null;
  };

  const calculateTotalWeight = (project: Project): number => {
    return (project.filaments || []).reduce((total, filament) => {
      return total + (filament.weight || 0);
    }, 0);
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const toggleRow = (projectId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(projectId)) {
      newExpandedRows.delete(projectId);
    } else {
      newExpandedRows.add(projectId);
    }
    setExpandedRows(newExpandedRows);
  };

  const toggleGroup = (groupId: number, e: React.MouseEvent) => {
    e.stopPropagation();
    const newCollapsedGroups = new Set(collapsedGroups);
    if (collapsedGroups.has(groupId)) {
      newCollapsedGroups.delete(groupId);
    } else {
      newCollapsedGroups.add(groupId);
    }
    setCollapsedGroups(newCollapsedGroups);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const groupedProjects = useMemo(() => {
    let filtered = projects;
    
    if (filter) {
      const lowercaseFilter = filter.toLowerCase();
      filtered = projects.filter(project => 
        project.name.toLowerCase().includes(lowercaseFilter) ||
        project.client.toLowerCase().includes(lowercaseFilter) ||
        project.printer.toLowerCase().includes(lowercaseFilter)
      );
    }

    const groups = new Map<number, ProjectGroup>();
    const ungroupedProjects: Project[] = [];
    
    filtered.forEach(project => {
      const printer = getPrinterFromString(project.printer);
      if (!printer) {
        ungroupedProjects.push(project);
        return;
      }
      
      if (!groups.has(printer.id)) {
        groups.set(printer.id, {
          printer,
          projects: []
        });
      }
      
      groups.get(printer.id)?.projects.push(project);
    });

    if (ungroupedProjects.length > 0 && printers.length > 0) {
      const firstPrinter = printers[0];
      const printer: PrinterConfig = {
        id: firstPrinter.id,
        name: firstPrinter.name,
        model: firstPrinter.model,
        outOfOrder: firstPrinter.out_of_order || false
      };

      if (!groups.has(printer.id)) {
        groups.set(printer.id, {
          printer,
          projects: []
        });
      }

      groups.get(printer.id)?.projects.push(...ungroupedProjects);
    }

    groups.forEach(group => {
      group.projects.sort((a, b) => {
        let comparison = 0;
        const direction = sortDirection === 'asc' ? 1 : -1;

        switch (sortField) {
          case 'name':
          case 'client':
          case 'status':
          case 'printer':
            comparison = a[sortField].localeCompare(b[sortField]);
            break;
          case 'print_weight':
            comparison = calculateTotalWeight(a) - calculateTotalWeight(b);
            break;
          case 'print_time':
          case 'selling_price':
            comparison = a[sortField] - b[sortField];
            break;
          case 'cost':
            comparison = (a.material_cost || 0) - (b.material_cost || 0);
            break;
          case 'profit':
            const profitA = a.selling_price - (a.material_cost || 0);
            const profitB = b.selling_price - (b.material_cost || 0);
            comparison = profitA - profitB;
            break;
        }

        return comparison * direction;
      });
    });

    return Array.from(groups.values()).sort((a, b) => a.printer.id - b.printer.id);
  }, [projects, sortField, sortDirection, filter, printers]);

  return {
    groupedProjects,
    filter,
    setFilter,
    expandedRows,
    collapsedGroups,
    showToast,
    setShowToast,
    handleSort,
    toggleRow,
    toggleGroup,
    copyToClipboard,
    calculateTotalWeight,
    getDisplayId
  };
}
