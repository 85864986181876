import type { FC } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  MenuIcon,
  CloseIcon,
  HomeIcon,
  CalendarIcon,
  FilamentsIcon,
  TaxIcon,
  PrintersIcon,
  ProfileIcon,
  SignOutIcon
} from './icons';
import { useAuthContext } from '../hooks/useAuthContext';
import '../styles/Header.css';

interface MenuItemProps {
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

interface HeaderProps {
  onSettingsClick: () => void;
  onNewProjectClick: () => void;
  onFilamentsClick: () => void;
  onTaxClick: () => void;
  onHomeClick: () => void;
  onCalendarClick: () => void;
}

const MenuItem: FC<MenuItemProps> = ({ onClick, className, children }) => (
  <button
    onClick={onClick}
    className={`menu-item ${className || ''}`}
  >
    {children}
  </button>
);

export const Header: FC<HeaderProps> = ({
  onSettingsClick,
  onNewProjectClick,
  onFilamentsClick,
  onTaxClick,
  onHomeClick,
  onCalendarClick
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const auth = useAuthContext();

  const handleSignOut = async () => {
    try {
      const { error } = await auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleNavigation = (callback: () => void) => {
    callback();
    setIsMenuOpen(false);
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <header className="header">
      <div className="header-content">
        <h1 className="header-title">Printing Tracker</h1>
        <button
          className="menu-toggle"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        >
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>
      </div>

      <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
        <MenuItem
          onClick={() => handleNavigation(onHomeClick)}
          className={isActive('/') ? 'active' : ''}
        >
          <HomeIcon /> Home
        </MenuItem>

        <MenuItem
          onClick={() => handleNavigation(onCalendarClick)}
          className={isActive('/calendar') ? 'active' : ''}
        >
          <CalendarIcon /> Calendar
        </MenuItem>

        <MenuItem
          onClick={() => handleNavigation(onFilamentsClick)}
          className={isActive('/filaments') ? 'active' : ''}
        >
          <FilamentsIcon /> Filaments
        </MenuItem>

        <MenuItem
          onClick={() => handleNavigation(onTaxClick)}
          className={isActive('/tax') ? 'active' : ''}
        >
          <TaxIcon /> Tax
        </MenuItem>

        <MenuItem
          onClick={onSettingsClick}
          className={isActive('/printers') ? 'active' : ''}
        >
          <PrintersIcon /> Printers
        </MenuItem>

        <MenuItem
          onClick={() => handleNavigation(() => {})}
          className={isActive('/profile') ? 'active' : ''}
        >
          <ProfileIcon /> Profile
        </MenuItem>

        <MenuItem onClick={handleSignOut}>
          <SignOutIcon /> Sign Out
        </MenuItem>

        <MenuItem onClick={onNewProjectClick}>
          + New Project
        </MenuItem>
      </nav>
    </header>
  );
};
