/**
 * useProjects Hook
 * Custom hook for managing 3D printing projects in the application.
 */
import { useState, useEffect, useCallback } from 'react';
import { Project, ProjectStatus } from '../types';
import { supabase } from '../lib/supabase';
import { useAuthContext } from './useAuthContext';
import { createError } from '../utils/errorHandling';

interface UseProjectsReturn {
  projects: Project[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<void>;
  saveProject: (projectData: Omit<Project, 'id' | 'created_at'>, id?: string) => Promise<void>;
  deleteProject: (id: string) => Promise<void>;
  updateProjectStatus: (id: string, newStatus: ProjectStatus) => Promise<void>;
}

export function useProjects(): UseProjectsReturn {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuthContext();

  const loadProjects = useCallback(async () => {
    console.log('loadProjects called, auth state:', { 
      hasUser: !!auth.user,
      userId: auth.user?.id,
      timestamp: new Date().toISOString()
    });

    if (!auth.user?.id) {
      console.log('No user found, clearing projects');
      setProjects([]);
      setIsLoading(false);
      return;
    }

    const userId = auth.user.id;

    try {
      setIsLoading(true);
      setError(null);
      console.log('Fetching projects for user:', userId);

      // First, verify the user exists in the profiles table
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('id')
        .eq('id', userId)
        .single();

      if (profileError) {
        console.error('Error fetching user profile:', profileError);
        throw createError('Failed to verify user profile');
      }

      if (!profile) {
        console.error('User profile not found');
        throw createError('User profile not found');
      }

      console.log('User profile verified:', profile.id);

      // Now fetch the projects
      const { data: projectsData, error: projectsError } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (projectsError) {
        console.error('Error fetching projects:', projectsError);
        throw createError(`Failed to load projects: ${projectsError.message}`);
      }

      if (!projectsData) {
        console.log('No projects data returned');
        setProjects([]);
        return;
      }

      // Fetch all filaments for validation
      const { data: filamentsData, error: filamentsError } = await supabase
        .from('filaments')
        .select('*')
        .eq('user_id', userId);

      if (filamentsError) {
        console.error('Error fetching filaments:', filamentsError);
        throw createError(`Failed to load filaments: ${filamentsError.message}`);
      }

      // Transform and validate the projects data
      const transformedProjects: Project[] = projectsData.map(project => {
        // Validate filaments
        const validatedFilaments = (project.filaments || []).map(filament => {
          const filamentExists = filamentsData?.some(f => f.id === filament.filament_id);
          
          if (!filamentExists) {
            console.warn('Invalid filament reference:', {
              projectId: project.id,
              projectName: project.name,
              filamentId: filament.filament_id
            });
          }

          return {
            ...filament,
            filament_id: filament.filament_id,
            weight: Number(filament.weight),
            cost: Number(filament.cost)
          };
        });

        return {
          id: project.id,
          name: project.name,
          client: project.client,
          printer: project.printer,
          print_time: Number(project.print_time),
          material_cost: Number(project.material_cost),
          selling_price: Number(project.selling_price),
          status: project.status as ProjectStatus,
          created_at: project.created_at,
          filaments: validatedFilaments,
          user_id: project.user_id
        };
      });

      console.log('Transformed projects:', {
        count: transformedProjects.length,
        firstProject: transformedProjects[0]
      });

      setProjects(transformedProjects);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load projects';
      console.error('Error in loadProjects:', errorMessage);
      setError(errorMessage);
      setProjects([]);
    } finally {
      setIsLoading(false);
    }
  }, [auth.user?.id]);

  const saveProject = async (projectData: Omit<Project, 'id' | 'created_at'>, id?: string) => {
    if (!auth.user?.id) {
      throw createError('User must be authenticated to save a project');
    }

    const userId = auth.user.id;

    try {
      console.log('Saving project:', {
        isUpdate: !!id,
        projectName: projectData.name,
        userId,
        projectData
      });

      // Ensure all numeric fields are properly converted
      const dataToSave = {
        ...projectData,
        print_time: Number(projectData.print_time),
        material_cost: Number(projectData.material_cost),
        selling_price: Number(projectData.selling_price),
        filaments: projectData.filaments.map(f => ({
          ...f,
          filament_id: f.filament_id,
          weight: Number(f.weight),
          cost: Number(f.cost)
        })),
        user_id: userId
      };

      if (id) {
        // Update existing project
        const { data, error: updateError } = await supabase
          .from('projects')
          .update(dataToSave)
          .eq('id', id)
          .eq('user_id', userId)
          .select();

        if (updateError) throw createError(updateError);
        console.log('Project updated successfully:', { id, updatedData: data?.[0] });
      } else {
        // Add new project
        const { data, error: insertError } = await supabase
          .from('projects')
          .insert([dataToSave])
          .select();

        if (insertError) throw createError(insertError);
        console.log('New project created successfully:', data?.[0]);
      }
      
      await loadProjects();
    } catch (error) {
      console.error('Error saving project:', error);
      throw createError('Failed to save project');
    }
  };

  const deleteProject = async (id: string) => {
    if (!auth.user?.id) {
      throw createError('User must be authenticated to delete a project');
    }

    const userId = auth.user.id;

    try {
      console.log('Deleting project:', { id, userId });

      const { error: deleteError } = await supabase
        .from('projects')
        .delete()
        .eq('id', id)
        .eq('user_id', userId);

      if (deleteError) throw createError(deleteError);
      
      console.log('Project deleted successfully:', id);
      await loadProjects();
    } catch (error) {
      console.error('Error deleting project:', error);
      throw createError('Failed to delete project');
    }
  };

  const updateProjectStatus = async (id: string, newStatus: ProjectStatus) => {
    if (!auth.user?.id) {
      throw createError('User must be authenticated to update project status');
    }

    const userId = auth.user.id;

    try {
      console.log('Updating project status:', {
        id,
        newStatus,
        userId
      });

      const { data, error: updateError } = await supabase
        .from('projects')
        .update({ status: newStatus })
        .eq('id', id)
        .eq('user_id', userId)
        .select();

      if (updateError) throw createError(updateError);
      
      console.log('Project status updated successfully:', { 
        id, 
        newStatus,
        updatedProject: data?.[0]
      });
      await loadProjects();
    } catch (error) {
      console.error('Error updating project status:', error);
      throw createError('Failed to update project status');
    }
  };

  useEffect(() => {
    console.log('useProjects effect triggered:', {
      hasUser: !!auth.user,
      userId: auth.user?.id,
      timestamp: new Date().toISOString()
    });
    loadProjects();
  }, [loadProjects]);

  return {
    projects,
    isLoading,
    error,
    refetch: loadProjects,
    saveProject,
    deleteProject,
    updateProjectStatus
  };
}
