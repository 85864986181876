/**
 * Formatting Utilities
 * Collection of functions for consistent data formatting throughout the application.
 * Handles currency, weight, time, and percentage formatting with locale support.
 */

/**
 * Formats a number as currency in USD
 * Uses the US locale and ensures consistent decimal places
 * 
 * @param {number} amount - The monetary amount to format
 * @returns {string} Formatted currency string (e.g., "$123.45")
 * 
 * @example
 * formatCurrency(123.456) // Returns "$123.46"
 * formatCurrency(1000) // Returns "$1,000.00"
 */
export const formatCurrency = (amount: number): string => 
  new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  }).format(amount);

/**
 * Formats a number as weight in grams
 * Rounds to one decimal place and appends 'g' unit
 * 
 * @param {number} grams - Weight in grams to format
 * @returns {string} Formatted weight string (e.g., "123.4g")
 * 
 * @example
 * formatWeight(123.456) // Returns "123.5g"
 * formatWeight(1000) // Returns "1000.0g"
 */
export const formatWeight = (grams: number): string => 
  `${grams.toFixed(1)}g`;

/**
 * Formats a number as time in hours
 * Rounds to one decimal place and appends 'h' unit
 * 
 * @param {number} hours - Time in hours to format
 * @returns {string} Formatted time string (e.g., "12.5h")
 * 
 * @example
 * formatTime(12.345) // Returns "12.3h"
 * formatTime(1) // Returns "1.0h"
 */
export const formatTime = (hours: number): string => 
  `${hours.toFixed(1)}h`;

/**
 * Formats a ratio as a percentage
 * Calculates percentage from part and total, rounds to one decimal place
 * Returns "0.0" if total is zero to avoid division by zero
 * 
 * @param {number} part - The numerator of the ratio
 * @param {number} total - The denominator of the ratio
 * @returns {string} Formatted percentage string (e.g., "75.0")
 * 
 * @example
 * formatPercentage(75, 100) // Returns "75.0"
 * formatPercentage(1, 3) // Returns "33.3"
 * formatPercentage(1, 0) // Returns "0.0"
 */
export const formatPercentage = (part: number, total: number): string => 
  total > 0 ? `${((part / total) * 100).toFixed(1)}` : '0.0';
