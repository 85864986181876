import { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { SuccessModal } from './SuccessModal';
import './Auth.css';

interface SignUpProps {
  onToggleForm: () => void;
}

export function SignUp({ onToggleForm }: SignUpProps) {
  const auth = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      console.log('Attempting sign up for:', email);
      const { error } = await auth.signUp({ email, password, username });
      if (error) throw error;
      
      // Show success modal
      console.log('Sign up successful');
      setShowSuccessModal(true);
    } catch (err) {
      console.error('Sign up error:', err);
      setError(err instanceof Error ? err.message : 'Failed to sign up');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="auth-container">
        <div className="auth-card">
          <h2>Sign Up</h2>
          {error && <div className="auth-error">{error}</div>}
          <form onSubmit={handleSubmit} className="auth-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
                disabled={loading}
                autoComplete="email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="Choose a username"
                disabled={loading}
                autoComplete="username"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Choose a password"
                disabled={loading}
                autoComplete="new-password"
              />
            </div>
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Signing up...' : 'Sign Up'}
            </button>
          </form>
          <p className="auth-toggle">
            Already have an account?{' '}
            <button onClick={onToggleForm} className="auth-link" disabled={loading}>
              Sign In
            </button>
          </p>
        </div>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          onToggleForm();
        }}
        heading="Sign Up Successful"
        message="Your account has been created successfully. Please sign in with your new credentials."
      />
    </>
  );
}
