/**
 * Core Type Definitions
 * Central type definitions used throughout the application.
 * These types define the shape of data structures and ensure type safety.
 */

/**
 * Project
 * Represents a 3D printing project with all its properties
 */
export interface Project {
  id: string;              // Unique identifier
  name: string;            // Project name
  client: string;          // Client name
  printer: string;         // Printer used
  print_time: number;      // Print duration in hours
  material_cost: number;   // Total material cost
  selling_price: number;   // Project price
  status: ProjectStatus;   // Current project status
  created_at: string;      // Creation timestamp
  filaments: ProjectFilament[]; // Filaments used in project
  user_id: string;         // User who owns this project
}

/**
 * ProjectFilament
 * Represents filament usage within a project
 */
export interface ProjectFilament {
  filament_id: string;  // Reference to filament ID
  weight: number;       // Weight used in grams
  cost: number;        // Cost per kg at time of use
}

/**
 * Filament
 * Represents a filament type in inventory
 */
export interface Filament {
  id: string;           // Unique identifier
  brand: string;        // Manufacturer/brand name
  color: string;        // Filament color
  quantity: number;     // Available quantity in kg
  type: string;         // Material type (PLA, PETG, etc.)
  cost: number;         // Cost per kg
  td: number;          // Temperature dependency rating
  usedWeight?: number;  // Optional tracking of used amount
  user_id: string;      // User who owns this filament
}

/**
 * TaxRates
 * Stores tax rates for different jurisdictions
 */
export interface TaxRates {
  federal: number;  // Federal tax rate percentage
  state: number;    // State tax rate percentage
  local: number;    // Local tax rate percentage
}

/**
 * PrinterMetricsData
 * Basic printer performance metrics
 */
export interface PrinterMetricsData {
  name: string;        // Printer name
  model: string;       // Printer model
  printTime: number;   // Total print time
  projects: number;    // Number of projects
  revenue: number;     // Total revenue generated
  materialCost: number; // Total material cost
}

/**
 * Metrics
 * Comprehensive business metrics and statistics
 */
export interface Metrics {
  totalProjects: number;      // Total number of projects
  totalPrintTime: number;     // Total print time across all printers
  totalMaterialCost: number;  // Total material costs
  totalRevenue: number;       // Total revenue
  totalCosts: number;         // Total costs
  totalProfit: number;        // Gross profit
  netProfit: number;          // Profit after taxes
  averageMargin: number;      // Average profit margin percentage
  pendingProjects: number;    // Number of pending projects
  printingProjects: number;   // Number of active prints
  completedProjects: number;  // Number of completed projects
  failedProjects: number;     // Number of failed projects
  printerMetrics: Record<string, PrinterMetric>; // Per-printer metrics
}

/**
 * PrinterMetric
 * Detailed metrics for an individual printer
 */
export interface PrinterMetric {
  time: number;        // Total operational time
  name: string;        // Printer name
  model: string;       // Printer model
  projects: number;    // Number of projects
  printTime: number;   // Total print time
  materialCost: number; // Total material cost
  revenue: number;     // Total revenue generated
}

/**
 * PrinterConfig
 * Printer configuration and status
 */
export interface PrinterConfig {
  id: number;          // Unique identifier
  name: string;        // Printer name
  model: string;       // Printer model
  outOfOrder: boolean; // Operational status
}

/**
 * ProjectStatus
 * Possible states for a project
 */
export type ProjectStatus = 'pending' | 'printing' | 'completed' | 'failed';

/**
 * Array of all possible project statuses
 * Used for status selection and validation
 */
export const PROJECT_STATUSES: ProjectStatus[] = ['pending', 'printing', 'completed', 'failed'];
