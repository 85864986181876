import { useState, useEffect } from 'react';
import { Filament } from '../types';
import { useAuthContext } from '../hooks/useAuthContext';
import '../styles/FilamentModal.css';

interface FilamentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (filamentData: Omit<Filament, 'id' | 'created_at' | 'updated_at'>) => void;
  editingFilament?: Filament;
  isEditing?: boolean;
}

export function FilamentModal({
  isOpen,
  onClose,
  onSubmit,
  editingFilament,
  isEditing = false
}: FilamentModalProps) {
  const [brand, setBrand] = useState('');
  const [type, setType] = useState('');
  const [color, setColor] = useState('');
  const [quantity, setQuantity] = useState('');
  const [cost, setCost] = useState('');
  const [td, setTd] = useState('');
  const auth = useAuthContext();

  useEffect(() => {
    if (editingFilament) {
      setBrand(editingFilament.brand);
      setType(editingFilament.type);
      setColor(editingFilament.color);
      setQuantity(editingFilament.quantity.toString());
      setCost(editingFilament.cost.toString());
      setTd(editingFilament.td.toString());
    } else {
      resetForm();
    }
  }, [editingFilament]);

  const resetForm = () => {
    setBrand('');
    setType('');
    setColor('');
    setQuantity('');
    setCost('');
    setTd('');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!auth.user?.id) {
      console.error('User must be logged in to add filaments');
      return;
    }

    const filamentData = {
      brand,
      type,
      color,
      quantity: parseFloat(quantity) || 0,
      cost: parseFloat(cost) || 0,
      td: parseFloat(td) || 0,
      user_id: auth.user.id
    };

    onSubmit(filamentData);
    resetForm();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>{isEditing ? 'Edit Filament' : 'Add New Filament'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="brand">Brand:</label>
            <input
              type="text"
              id="brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="type">Type:</label>
            <input
              type="text"
              id="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="color">Color:</label>
            <input
              type="text"
              id="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="quantity">Quantity (kg):</label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min="0"
              step="0.001"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="cost">Cost per kg ($):</label>
            <input
              type="number"
              id="cost"
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              min="0"
              step="0.01"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="td">Temperature Dependency:</label>
            <input
              type="number"
              id="td"
              value={td}
              onChange={(e) => setTd(e.target.value)}
              min="0"
              step="0.1"
              required
            />
          </div>

          <div className="modal-actions">
            <button type="button" onClick={onClose} className="cancel-btn">
              Cancel
            </button>
            <button type="submit" className="submit-btn">
              {isEditing ? 'Save Changes' : 'Add Filament'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
