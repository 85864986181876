import { useFilaments } from '../../hooks/useFilaments';
import { useViewport } from '../../hooks/useViewport';
import { ProjectsTableMobile } from './ProjectsTableMobile';
import { Toast } from './Toast';
import { PrinterGroup } from './PrinterGroup';
import { useProjectsTable } from './useProjectsTable';
import { ProjectsTableProps } from './types';
import { FilamentProvider } from './FilamentContext';
import { ProjectStatus, PROJECT_STATUSES } from '../../types';
import '../../styles/ProjectsTable.css';

const ProjectsTable = ({
  projects,
  onStatusChange,
  onEdit,
  onDelete,
  onDuplicate
}: ProjectsTableProps) => {
  const { filaments } = useFilaments();
  const { isMobile } = useViewport();
  const {
    groupedProjects,
    filter,
    setFilter,
    expandedRows,
    collapsedGroups,
    showToast,
    setShowToast,
    toggleRow,
    toggleGroup,
    copyToClipboard,
    calculateTotalWeight,
    getDisplayId
  } = useProjectsTable(projects);

  const handleStatusChange = (id: string, status: string) => {
    if (PROJECT_STATUSES.includes(status as ProjectStatus)) {
      onStatusChange(id, status as ProjectStatus);
    }
  };

  if (!Array.isArray(projects) || projects.length === 0) {
    return <p className="no-projects">No projects found. Click "New Project" to add one.</p>;
  }

  const content = isMobile ? (
    <ProjectsTableMobile
      projects={groupedProjects.flatMap(group => group.projects)}
      onStatusChange={onStatusChange}
      onEdit={onEdit}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      filter={filter}
      setFilter={setFilter}
      expandedRows={expandedRows}
      toggleRow={toggleRow}
    />
  ) : (
    <div className="projects-table-container">
      {showToast && (
        <Toast 
          message="ID copied to clipboard" 
          onClose={() => setShowToast(false)} 
        />
      )}
      <div className="table-controls">
        <input
          type="text"
          placeholder="Filter projects..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="filter-input"
        />
      </div>

      <div className="table-wrapper">
        <table className="projects-table">
          {groupedProjects.map((group) => (
            <PrinterGroup
              key={group.printer.id}
              printer={group.printer}
              projects={group.projects}
              isCollapsed={collapsedGroups.has(group.printer.id)}
              expandedRows={expandedRows}
              onToggleGroup={(e) => toggleGroup(group.printer.id, e)}
              onToggleRow={toggleRow}
              onStatusChange={handleStatusChange}
              onDuplicate={onDuplicate}
              onEdit={onEdit}
              onDelete={onDelete}
              calculateTotalWeight={calculateTotalWeight}
            />
          ))}
        </table>
      </div>
    </div>
  );

  return (
    <FilamentProvider
      filaments={filaments}
      onCopyId={copyToClipboard}
      getDisplayId={getDisplayId}
    >
      {content}
    </FilamentProvider>
  );
};

export default ProjectsTable;
