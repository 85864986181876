import React from 'react';
import { ProjectFilament } from '../../types';
import { FilamentRow } from './FilamentRow';
import { useFilamentContext } from './FilamentContext';

interface FilamentTableProps {
  projectFilaments: ProjectFilament[];
}

export const FilamentTable: React.FC<FilamentTableProps> = ({
  projectFilaments
}) => {
  const { filaments, onCopyId, getDisplayId } = useFilamentContext();

  return (
    <div className="filament-list">
      <h4>Filaments Used:</h4>
      <table className="filament-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Brand</th>
            <th>Type</th>
            <th>Color</th>
            <th>TD</th>
            <th>Weight</th>
            <th>Cost/kg</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {projectFilaments.map((projectFilament, index) => {
            const filament = filaments.find(f => f.id === projectFilament.filament_id);
            const displayId = getDisplayId(projectFilament.filament_id);
            return (
              <FilamentRow
                key={index}
                projectFilament={projectFilament}
                filament={filament}
                displayId={displayId}
                onCopyId={onCopyId}
              />
            );
          })}
          {(!projectFilaments || projectFilaments.length === 0) && (
            <tr>
              <td colSpan={8} style={{ textAlign: 'center', padding: '1rem', color: '#666' }}>
                No filaments added to this project.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
