import React from 'react';
import { MdEdit, MdDelete, MdContentCopy } from 'react-icons/md';
import { Project } from '../../types';

interface ProjectActionsProps {
  project: Project;
  onDuplicate: (project: Project) => void;
  onEdit: (project: Project) => void;
  onDelete: (project: Project) => void;
}

export const ProjectActions: React.FC<ProjectActionsProps> = ({
  project,
  onDuplicate,
  onEdit,
  onDelete
}) => {
  return (
    <div className="actions">
      <button 
        className="icon-btn duplicate-btn" 
        onClick={() => onDuplicate(project)} 
        title="Duplicate project"
      >
        <MdContentCopy size={24} />
      </button>
      <button 
        className="icon-btn edit-btn" 
        onClick={() => onEdit(project)} 
        title="Edit project"
      >
        <MdEdit size={24} />
      </button>
      <button 
        className="icon-btn delete-btn" 
        onClick={() => onDelete(project)} 
        title="Delete project"
      >
        <MdDelete size={24} />
      </button>
    </div>
  );
};
