import React from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { Project, PrinterConfig } from '../../types';
import { formatCurrency, formatWeight, formatTime } from '../../utils/formatting';
import { ProjectActions } from './ProjectActions';
import { ProjectInfo } from './ProjectInfo';
import { StatusSelect } from './StatusSelect';
import { FilamentTable } from './FilamentTable';

interface ProjectRowProps {
  project: Project;
  isExpanded: boolean;
  printer: PrinterConfig | null;
  onToggleExpand: (e: React.MouseEvent) => void;
  onStatusChange: (id: string, status: string) => void;
  onDuplicate: (project: Project) => void;
  onEdit: (project: Project) => void;
  onDelete: (project: Project) => void;
  calculateTotalWeight: (project: Project) => number;
}

export const ProjectRow: React.FC<ProjectRowProps> = ({
  project,
  isExpanded,
  printer,
  onToggleExpand,
  onStatusChange,
  onDuplicate,
  onEdit,
  onDelete,
  calculateTotalWeight
}) => {
  const profit = project.selling_price - (project.material_cost || 0);
  const totalWeight = calculateTotalWeight(project);

  return (
    <React.Fragment>
      <tr className={isExpanded ? 'expanded' : ''}>
        <td>
          <button 
            className="icon-btn"
            onClick={onToggleExpand}
            title={isExpanded ? 'Collapse' : 'Expand'}
          >
            {isExpanded ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
          </button>
        </td>
        <td>{project.name}</td>
        <td>{project.client}</td>
        <td>
          <StatusSelect
            status={project.status}
            onStatusChange={(status) => onStatusChange(project.id, status)}
          />
        </td>
        <td>{formatWeight(totalWeight)}</td>
        <td>{formatTime(project.print_time)}</td>
        <td>
          <ProjectInfo printer={printer} />
        </td>
        <td>{formatCurrency(project.material_cost || 0)}</td>
        <td>{formatCurrency(project.selling_price)}</td>
        <td className={`profit ${profit < 0 ? 'negative' : ''}`}>
          {formatCurrency(profit)}
        </td>
        <td>
          <ProjectActions
            project={project}
            onDuplicate={onDuplicate}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </td>
      </tr>

      {isExpanded && project.filaments && (
        <tr className="filament-details">
          <td colSpan={11}>
            <FilamentTable projectFilaments={project.filaments} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};
