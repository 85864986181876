import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthContext } from './useAuthContext';
import { Metrics, PrinterMetric } from '../types';

export function usePrinterMetrics() {
  const [metrics, setMetrics] = useState<Metrics>({
    totalProjects: 0,
    totalPrintTime: 0,
    totalMaterialCost: 0,
    totalRevenue: 0,
    totalCosts: 0,
    totalProfit: 0,
    netProfit: 0,
    averageMargin: 0,
    pendingProjects: 0,
    printingProjects: 0,
    completedProjects: 0,
    failedProjects: 0,
    printerMetrics: {}
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuthContext();

  useEffect(() => {
    const loadMetrics = async () => {
      if (!auth.user?.id) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        // Fetch projects to calculate metrics
        const { data: projects, error: projectsError } = await supabase
          .from('projects')
          .select('*')
          .eq('user_id', auth.user.id);

        if (projectsError) throw projectsError;

        // Calculate metrics
        const printerMetrics: Record<string, PrinterMetric> = {};
        let totalPrintTime = 0;
        let totalProjects = 0;
        let totalMaterialCost = 0;
        let totalRevenue = 0;
        let pendingProjects = 0;
        let printingProjects = 0;
        let completedProjects = 0;
        let failedProjects = 0;

        projects?.forEach(project => {
          // Update project status counts
          switch (project.status) {
            case 'pending':
              pendingProjects++;
              break;
            case 'printing':
              printingProjects++;
              break;
            case 'completed':
              completedProjects++;
              break;
            case 'failed':
              failedProjects++;
              break;
          }

          // Skip projects that aren't completed for financial metrics
          if (project.status !== 'completed') return;

          const printerId = project.printer;
          if (!printerId) return;

          // Initialize printer metrics if not exists
          if (!printerMetrics[printerId]) {
            printerMetrics[printerId] = {
              time: 0,
              name: '',
              model: '',
              projects: 0,
              printTime: 0,
              materialCost: 0,
              revenue: 0
            };
          }

          // Update printer-specific metrics
          printerMetrics[printerId].projects++;
          printerMetrics[printerId].printTime += project.print_time || 0;
          printerMetrics[printerId].materialCost += project.material_cost || 0;
          printerMetrics[printerId].revenue += project.selling_price || 0;

          // Update totals
          totalPrintTime += project.print_time || 0;
          totalProjects++;
          totalMaterialCost += project.material_cost || 0;
          totalRevenue += project.selling_price || 0;
        });

        // Calculate derived metrics
        const totalCosts = totalMaterialCost; // Add other costs if needed
        const totalProfit = totalRevenue - totalCosts;
        const averageMargin = totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0;

        setMetrics({
          totalProjects,
          totalPrintTime,
          totalMaterialCost,
          totalRevenue,
          totalCosts,
          totalProfit,
          netProfit: totalProfit,
          averageMargin,
          pendingProjects,
          printingProjects,
          completedProjects,
          failedProjects,
          printerMetrics
        });
      } catch (err) {
        console.error('Error loading printer metrics:', err);
        setError(err instanceof Error ? err.message : 'Failed to load printer metrics');
      } finally {
        setIsLoading(false);
      }
    };

    loadMetrics();
  }, [auth.user?.id]);

  return { metrics, isLoading, error };
}
