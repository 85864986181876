import React, { useState, useMemo } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { PrinterGroupProps, SortField } from './types';
import { ProjectRow } from './ProjectRow';
import { TableHeader } from './TableHeader';

export const PrinterGroup: React.FC<PrinterGroupProps> = ({
  printer,
  projects,
  isCollapsed,
  expandedRows,
  onToggleGroup,
  onToggleRow,
  onStatusChange,
  onDuplicate,
  onEdit,
  onDelete,
  calculateTotalWeight
}) => {
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedProjects = useMemo(() => {
    return [...projects].sort((a, b) => {
      let comparison = 0;
      const direction = sortDirection === 'asc' ? 1 : -1;

      switch (sortField) {
        case 'name':
        case 'client':
        case 'status':
        case 'printer':
          comparison = a[sortField].localeCompare(b[sortField]);
          break;
        case 'print_weight':
          comparison = calculateTotalWeight(a) - calculateTotalWeight(b);
          break;
        case 'print_time':
        case 'selling_price':
          comparison = a[sortField] - b[sortField];
          break;
        case 'cost':
          comparison = (a.material_cost || 0) - (b.material_cost || 0);
          break;
        case 'profit':
          const profitA = a.selling_price - (a.material_cost || 0);
          const profitB = b.selling_price - (b.material_cost || 0);
          comparison = profitA - profitB;
          break;
      }

      return comparison * direction;
    });
  }, [projects, sortField, sortDirection, calculateTotalWeight]);

  const renderColumnHeaders = () => (
    <TableHeader onSort={handleSort} />
  );

  return (
    <tbody>
      <tr 
        className={`printer-group-header ${isCollapsed ? 'collapsed' : ''}`}
        onClick={onToggleGroup}
      >
        <td colSpan={11}>
          <div className="group-header-content">
            <span className="collapse-icon">
              {isCollapsed ? <MdExpandMore size={20} /> : <MdExpandLess size={20} />}
            </span>
            <span>
              {`Printer ${printer.id} - ${printer.name} - ${printer.model}`}
            </span>
          </div>
        </td>
      </tr>
      {!isCollapsed && (
        <>
          {renderColumnHeaders()}
          {sortedProjects.map(project => {
            const isExpanded = expandedRows.has(project.id);
            return (
              <ProjectRow
                key={project.id}
                project={project}
                isExpanded={isExpanded}
                printer={printer}
                onToggleExpand={(e) => onToggleRow(project.id, e)}
                onStatusChange={onStatusChange}
                onDuplicate={onDuplicate}
                onEdit={onEdit}
                onDelete={onDelete}
                calculateTotalWeight={calculateTotalWeight}
              />
            );
          })}
        </>
      )}
    </tbody>
  );
};
