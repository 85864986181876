import React from 'react';
import { ProjectStatus, PROJECT_STATUSES } from '../../types';

interface StatusSelectProps {
  status: ProjectStatus;
  onStatusChange: (value: string) => void;
}

export const StatusSelect: React.FC<StatusSelectProps> = ({
  status,
  onStatusChange
}) => {
  return (
    <select 
      value={status}
      onChange={(e) => onStatusChange(e.target.value)}
      className={`status-${status.toLowerCase()}`}
    >
      {PROJECT_STATUSES.map(status => (
        <option key={status} value={status}>
          {status}
        </option>
      ))}
    </select>
  );
};
