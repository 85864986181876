interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  message: string;
}

export function SuccessModal({ isOpen, onClose, heading, message }: SuccessModalProps) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{heading}</h2>
        <p>{message}</p>
        <button onClick={onClose} className="modal-button">
          Close
        </button>
      </div>
    </div>
  );
}

export type { SuccessModalProps };
