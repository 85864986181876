import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthContext } from './useAuthContext';
import { PrinterConfig } from '../types';

interface UsePrinterSettingsReturn {
  printerNames: PrinterConfig[];
  isLoading: boolean;
  error: string | null;
  savePrinterName: (printer: { name: string; model: string }) => Promise<void>;
  deletePrinterName: (id: number) => Promise<void>;
  togglePrinterOutOfOrder: (id: number) => Promise<void>;
  refetch: () => Promise<void>;
}

export function usePrinterSettings(): UsePrinterSettingsReturn {
  const [printerNames, setPrinterNames] = useState<PrinterConfig[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuthContext();

  const loadPrinterSettings = useCallback(async () => {
    if (!auth.user?.id) {
      setPrinterNames([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const { data, error: fetchError } = await supabase
        .from('printers')
        .select('*')
        .eq('user_id', auth.user.id)
        .order('id', { ascending: true });

      if (fetchError) throw fetchError;

      const transformedData: PrinterConfig[] = (data || []).map(printer => ({
        id: printer.id,
        name: printer.name,
        model: printer.model,
        outOfOrder: printer.out_of_order || false
      }));

      setPrinterNames(transformedData);
    } catch (err) {
      console.error('Error loading printer settings:', err);
      setError(err instanceof Error ? err.message : 'Failed to load printer settings');
      setPrinterNames([]);
    } finally {
      setIsLoading(false);
    }
  }, [auth.user?.id]);

  const savePrinterName = async (printer: { name: string; model: string }) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to save printer settings');
    }

    try {
      const { error: saveError } = await supabase
        .from('printers')
        .insert([{
          ...printer,
          out_of_order: false,
          user_id: auth.user.id
        }]);

      if (saveError) throw saveError;

      await loadPrinterSettings();
    } catch (error) {
      console.error('Error saving printer name:', error);
      throw error;
    }
  };

  const deletePrinterName = async (id: number) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to delete printer settings');
    }

    try {
      const { error: deleteError } = await supabase
        .from('printers')
        .delete()
        .eq('id', id)
        .eq('user_id', auth.user.id);

      if (deleteError) throw deleteError;

      await loadPrinterSettings();
    } catch (error) {
      console.error('Error deleting printer name:', error);
      throw error;
    }
  };

  const togglePrinterOutOfOrder = async (id: number) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to update printer settings');
    }

    try {
      const printer = printerNames.find(p => p.id === id);
      if (!printer) {
        throw new Error('Printer not found');
      }

      const { error: updateError } = await supabase
        .from('printers')
        .update({ out_of_order: !printer.outOfOrder })
        .eq('id', id)
        .eq('user_id', auth.user.id);

      if (updateError) throw updateError;

      await loadPrinterSettings();
    } catch (error) {
      console.error('Error toggling printer out of order status:', error);
      throw error;
    }
  };

  useEffect(() => {
    loadPrinterSettings();
  }, [loadPrinterSettings]);

  return {
    printerNames,
    isLoading,
    error,
    savePrinterName,
    deletePrinterName,
    togglePrinterOutOfOrder,
    refetch: loadPrinterSettings
  };
}
