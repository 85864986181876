import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { PrinterSettings } from '../components/PrinterSettings';
import { usePrinterSettings } from '../hooks/usePrinterSettings';
import './MainLayout.css';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [isPrinterSettingsOpen, setIsPrinterSettingsOpen] = useState(false);
  const { refetch } = usePrinterSettings();
  const navigate = useNavigate();

  const handlePrinterSettingsClose = () => {
    setIsPrinterSettingsOpen(false);
    refetch();
  };

  const handleSettingsClick = () => {
    setIsPrinterSettingsOpen(true);
  };

  const handleNewProject = () => {
    console.log('MainLayout: New Project clicked');
    // Handle new project in the MainPage component
  };

  return (
    <div className="app">
      <div className="app-content">
        <Header
          onSettingsClick={handleSettingsClick}
          onNewProjectClick={handleNewProject}
          onFilamentsClick={() => navigate('/filaments')}
          onTaxClick={() => navigate('/tax')}
          onHomeClick={() => navigate('/')}
          onCalendarClick={() => navigate('/calendar')}
        />
        <main className="main-content">
          {children}
        </main>
      </div>
      <PrinterSettings
        isOpen={isPrinterSettingsOpen}
        onClose={handlePrinterSettingsClose}
      />
    </div>
  );
};
