import React from 'react';
import { PrinterConfig } from '../../types';

interface ProjectInfoProps {
  printer: PrinterConfig | null;
}

export const ProjectInfo: React.FC<ProjectInfoProps> = ({ printer }) => {
  if (!printer) return null;

  return (
    <div className="printer-info">
      <span className="printer-number">Printer {printer.id}</span>
      <span className="printer-name">{printer.name}</span>
      <span className="printer-model">{printer.model}</span>
    </div>
  );
};
