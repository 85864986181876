import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthContext } from './useAuthContext';

interface Filament {
  id: string;
  brand: string;
  color: string;
  quantity: number;
  type: string;
  cost: number;
  td: number;
  created_at: string;
  updated_at: string;
  user_id: string;
}

export function useFilaments() {
  const [filaments, setFilaments] = useState<Filament[]>([]);
  const [rawFilaments, setRawFilaments] = useState<Filament[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuthContext();

  const loadFilaments = useCallback(async () => {
    if (!auth.user?.id) {
      setFilaments([]);
      setRawFilaments([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const { data, error: fetchError } = await supabase
        .from('filaments')
        .select('*')
        .eq('user_id', auth.user.id)
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;

      const filamentsData = data || [];
      setRawFilaments(filamentsData);
      setFilaments(filamentsData);
    } catch (err) {
      console.error('Error loading filaments:', err);
      setError(err instanceof Error ? err.message : 'Failed to load filaments');
      setFilaments([]);
      setRawFilaments([]);
    } finally {
      setIsLoading(false);
    }
  }, [auth.user?.id]);

  useEffect(() => {
    loadFilaments();
  }, [loadFilaments]);

  const saveFilament = async (filamentData: Omit<Filament, 'id' | 'created_at' | 'updated_at' | 'user_id'>) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to save a filament');
    }

    try {
      const dataToSave = {
        ...filamentData,
        user_id: auth.user.id
      };

      const { error: saveError } = await supabase
        .from('filaments')
        .insert([dataToSave]);

      if (saveError) throw saveError;

      await loadFilaments();
    } catch (err) {
      console.error('Error saving filament:', err);
      throw err;
    }
  };

  const updateFilament = async (id: string, updates: Partial<Omit<Filament, 'id' | 'created_at' | 'updated_at' | 'user_id'>>) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to update a filament');
    }

    try {
      const { error: updateError } = await supabase
        .from('filaments')
        .update(updates)
        .eq('id', id)
        .eq('user_id', auth.user.id);

      if (updateError) throw updateError;

      await loadFilaments();
    } catch (err) {
      console.error('Error updating filament:', err);
      throw err;
    }
  };

  const deleteFilament = async (id: string) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to delete a filament');
    }

    try {
      const { error: deleteError } = await supabase
        .from('filaments')
        .delete()
        .eq('id', id)
        .eq('user_id', auth.user.id);

      if (deleteError) throw deleteError;

      await loadFilaments();
    } catch (err) {
      console.error('Error deleting filament:', err);
      throw err;
    }
  };

  return {
    filaments,
    rawFilaments,
    isLoading,
    error,
    saveFilament,
    updateFilament,
    deleteFilament,
    refetch: loadFilaments
  };
}
