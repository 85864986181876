/**
 * Main Application Component
 * This is the root component of the application that sets up routing and error handling.
 * It uses React Router for navigation and wraps the entire app in an ErrorBoundary
 * for graceful error handling.
 */
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from './components/ErrorBoundary';
import { AuthProvider } from './contexts/auth-provider';
import { useEffect } from 'react';
import { supabase } from './lib/supabase';
import { MainPage } from './pages/MainPage';
import { CalendarPage } from './pages/CalendarPage';
import { FilamentsPage } from './pages/FilamentsPage';
import { TaxPage } from './pages/TaxPage';
import { PrintersPage } from './pages/PrintersPage';
import { Auth } from './components/auth/Auth';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import './App.css';

/**
 * App Component
 * Serves as the application's root component.
 * Wraps the router in an ErrorBoundary and AuthProvider to handle errors and authentication.
 * 
 * @returns {JSX.Element} The root application component with routing configured
 */
function App() {
  // Set up auth state listener
  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session }, error }) => {
      console.log('Initial auth session:', {
        hasSession: !!session,
        userId: session?.user?.id,
        error: error?.message,
        timestamp: new Date().toISOString()
      });

      if (session?.user) {
        // Verify user profile exists
        supabase
          .from('profiles')
          .select('id, username')
          .eq('id', session.user.id)
          .single()
          .then(({ data: profile, error: profileError }) => {
            console.log('Profile check:', {
              hasProfile: !!profile,
              username: profile?.username,
              error: profileError?.message,
              timestamp: new Date().toISOString()
            });
          });
      }
    });

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', {
        event,
        userId: session?.user?.id,
        timestamp: new Date().toISOString()
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
  
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<MainPage />} />
              <Route path="/calendar" element={<CalendarPage />} />
              <Route path="/filaments" element={<FilamentsPage />} />
              <Route path="/tax" element={<TaxPage />} />
              <Route path="/printers" element={<PrintersPage />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
