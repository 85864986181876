import React from 'react';
import { MdEdit, MdDelete, MdExpandMore, MdExpandLess, MdContentCopy } from 'react-icons/md';
import { Project, ProjectStatus, PROJECT_STATUSES, ProjectFilament } from '../../types';
import { formatCurrency, formatWeight, formatTime } from '../../utils/formatting';
import { FilamentTable } from './FilamentTable';
import { ProjectsTableMobileProps } from './types';

export const ProjectsTableMobile: React.FC<ProjectsTableMobileProps> = ({
  projects,
  onStatusChange,
  onEdit,
  onDelete,
  onDuplicate,
  filter,
  setFilter,
  expandedRows,
  toggleRow
}) => {
  const calculateTotalWeight = (project: Project): number => {
    return (project.filaments || []).reduce((total: number, filament: ProjectFilament) => {
      return total + (filament.weight || 0);
    }, 0);
  };

  const handleStatusChange = (id: string, status: string) => {
    if (PROJECT_STATUSES.includes(status as ProjectStatus)) {
      onStatusChange(id, status as ProjectStatus);
    }
  };

  return (
    <div className="projects-table-mobile">
      <div className="mobile-filters">
        <input
          type="text"
          placeholder="Filter projects..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="filter-input"
        />
      </div>

      <div className="mobile-projects-list">
        {projects.map(project => {
          const isExpanded = expandedRows.has(project.id);
          const profit = project.selling_price - (project.material_cost || 0);
          const totalWeight = calculateTotalWeight(project);

          return (
            <div key={project.id} className="mobile-project-card">
              <div className="mobile-project-header" onClick={(e) => toggleRow(project.id, e)}>
                <div className="mobile-project-title">
                  <h3>{project.name}</h3>
                  <button className="expand-btn">
                    {isExpanded ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
                  </button>
                </div>
                <div className="mobile-project-subtitle">
                  <span>{project.client}</span>
                  <span className={`status-badge status-${project.status.toLowerCase()}`}>
                    {project.status}
                  </span>
                </div>
              </div>

              <div className={`mobile-project-details ${isExpanded ? 'expanded' : ''}`}>
                <div className="mobile-project-stats">
                  <div className="stat-row">
                    <label>Weight:</label>
                    <span>{formatWeight(totalWeight)}</span>
                  </div>
                  <div className="stat-row">
                    <label>Time:</label>
                    <span>{formatTime(project.print_time)}</span>
                  </div>
                  <div className="stat-row">
                    <label>Cost:</label>
                    <span>{formatCurrency(project.material_cost || 0)}</span>
                  </div>
                  <div className="stat-row">
                    <label>Price:</label>
                    <span>{formatCurrency(project.selling_price)}</span>
                  </div>
                  <div className="stat-row">
                    <label>Profit:</label>
                    <span className={profit < 0 ? 'negative' : ''}>
                      {formatCurrency(profit)}
                    </span>
                  </div>
                </div>

                <div className="mobile-project-status">
                  <select
                    value={project.status}
                    onChange={(e) => handleStatusChange(project.id, e.target.value)}
                    className={`status-${project.status.toLowerCase()}`}
                  >
                    {PROJECT_STATUSES.map(status => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </select>
                </div>

                {isExpanded && project.filaments && (
                  <div className="mobile-filaments-section">
                    <FilamentTable projectFilaments={project.filaments} />
                  </div>
                )}

                <div className="mobile-project-actions">
                  <button
                    onClick={() => onDuplicate(project)}
                    className="action-btn duplicate-btn"
                  >
                    <MdContentCopy size={24} />
                    <span>Duplicate</span>
                  </button>
                  <button
                    onClick={() => onEdit(project)}
                    className="action-btn edit-btn"
                  >
                    <MdEdit size={24} />
                    <span>Edit</span>
                  </button>
                  <button
                    onClick={() => onDelete(project)}
                    className="action-btn delete-btn"
                  >
                    <MdDelete size={24} />
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
