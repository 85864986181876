import { useState } from 'react';
import { PrinterSettings } from '../components/PrinterSettings';
import { PrinterMetrics } from '../components/PrinterMetrics';
import { MainLayout } from '../layouts/MainLayout';
import { usePrinterMetrics } from '../hooks/usePrinterMetrics';
import { Loading } from '../components/shared/Loading';
import { Error } from '../components/shared/Error';
import '../styles/PrintersPage.css';

export function PrintersPage() {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMetricsCollapsed, setIsMetricsCollapsed] = useState(false);
  const { metrics, isLoading, error } = usePrinterMetrics();

  if (isLoading) {
    return (
      <MainLayout>
        <Loading message="Loading printer metrics..." />
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <Error message={error} />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="printers-page">
        <div className="page-header">
          <h1>Printer Management</h1>
          <button 
            className="settings-button"
            onClick={() => setIsSettingsOpen(true)}
          >
            Configure Printers
          </button>
        </div>

        <PrinterMetrics 
          metrics={metrics}
          isCollapsed={isMetricsCollapsed}
          onToggleCollapse={(e) => {
            e.preventDefault();
            setIsMetricsCollapsed(!isMetricsCollapsed);
          }}
        />

        <PrinterSettings 
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
        />
      </div>
    </MainLayout>
  );
}
