import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TaxRates } from '../types';
import { useTaxRates } from '../hooks/useTaxRates';
import '../styles/Tax.css';

export function Tax() {
  const navigate = useNavigate();
  const { taxRates: initialRates, updateTaxRates } = useTaxRates();
  const [taxRates, setTaxRates] = useState<TaxRates>({
    federal: 0,
    state: 0,
    local: 0
  });

  useEffect(() => {
    if (initialRates) {
      setTaxRates(initialRates);
    }
  }, [initialRates]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateTaxRates(taxRates);
    navigate('/');
  };

  const handleCancel = () => {
    navigate('/');
  };

  const handleRateChange = (field: keyof TaxRates, value: string) => {
    const numValue = parseFloat(value) || 0;
    setTaxRates(prev => ({
      ...prev,
      [field]: numValue
    }));
  };

  return (
    <div className="tax-container">
      <h2>Tax Rates</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Federal Tax Rate (%)</label>
          <input
            type="number"
            value={taxRates.federal}
            onChange={(e) => handleRateChange('federal', e.target.value)}
            min="0"
            max="100"
            step="0.1"
            required
          />
        </div>

        <div className="form-group">
          <label>State Tax Rate (%)</label>
          <input
            type="number"
            value={taxRates.state}
            onChange={(e) => handleRateChange('state', e.target.value)}
            min="0"
            max="100"
            step="0.1"
            required
          />
        </div>

        <div className="form-group">
          <label>Local Tax Rate (%)</label>
          <input
            type="number"
            value={taxRates.local}
            onChange={(e) => handleRateChange('local', e.target.value)}
            min="0"
            max="100"
            step="0.1"
            required
          />
        </div>

        <div className="form-summary">
          <p>Total Tax Rate: {(taxRates.federal + taxRates.state + taxRates.local).toFixed(1)}%</p>
        </div>

        <div className="form-actions">
          <button type="button" onClick={handleCancel} className="cancel-btn">Cancel</button>
          <button type="submit" className="save-btn">Save Changes</button>
        </div>
      </form>
    </div>
  );
}
