import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthContext } from './useAuthContext';

interface Printer {
  id: number;
  name: string;
  model: string;
  out_of_order: boolean;
  created_at: string;
  updated_at: string;
  user_id: string;
}

export function usePrinters() {
  const [printers, setPrinters] = useState<Printer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuthContext();

  const loadPrinters = useCallback(async () => {
    if (!auth.user?.id) {
      setPrinters([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      console.log('Loading printers for user:', auth.user.id);

      const { data, error: fetchError } = await supabase
        .from('printers')
        .select('*')
        .eq('user_id', auth.user.id)
        .order('id', { ascending: true }); // Order by ID to ensure consistent ordering

      if (fetchError) throw fetchError;

      console.log('Loaded printers:', {
        count: data?.length || 0,
        printers: data?.map(p => ({
          id: p.id,
          name: p.name
        }))
      });

      setPrinters(data || []);
    } catch (err) {
      console.error('Error loading printers:', err);
      setError(err instanceof Error ? err.message : 'Failed to load printers');
      setPrinters([]);
    } finally {
      setIsLoading(false);
    }
  }, [auth.user?.id]);

  useEffect(() => {
    loadPrinters();
  }, [loadPrinters]);

  const savePrinter = async (printerData: Omit<Printer, 'id' | 'created_at' | 'updated_at' | 'user_id'>) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to save a printer');
    }

    try {
      const dataToSave = {
        ...printerData,
        user_id: auth.user.id
      };

      const { error: saveError } = await supabase
        .from('printers')
        .insert([dataToSave]);

      if (saveError) throw saveError;

      await loadPrinters();
    } catch (err) {
      console.error('Error saving printer:', err);
      throw err;
    }
  };

  const updatePrinter = async (id: number, updates: Partial<Omit<Printer, 'id' | 'created_at' | 'updated_at' | 'user_id'>>) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to update a printer');
    }

    try {
      const { error: updateError } = await supabase
        .from('printers')
        .update(updates)
        .eq('id', id)
        .eq('user_id', auth.user.id);

      if (updateError) throw updateError;

      await loadPrinters();
    } catch (err) {
      console.error('Error updating printer:', err);
      throw err;
    }
  };

  const deletePrinter = async (id: number) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to delete a printer');
    }

    try {
      const { error: deleteError } = await supabase
        .from('printers')
        .delete()
        .eq('id', id)
        .eq('user_id', auth.user.id);

      if (deleteError) throw deleteError;

      await loadPrinters();
    } catch (err) {
      console.error('Error deleting printer:', err);
      throw err;
    }
  };

  return {
    printers,
    isLoading,
    error,
    savePrinter,
    updatePrinter,
    deletePrinter,
    refetch: loadPrinters
  };
}
