/**
 * Supabase Client Configuration
 * Sets up and exports the Supabase client instance with proper typing.
 * This is the central point for database connectivity in the application.
 */
import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';
import { createError } from '../utils/errorHandling';

/**
 * Environment variables for Supabase configuration
 * These should be defined in the .env file:
 * VITE_SUPABASE_URL: URL of your Supabase instance
 * VITE_SUPABASE_ANON_KEY: Anonymous API key for public access
 */
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

/**
 * Validate environment variables
 * Throws error if required variables are missing
 */
if (!supabaseUrl || !supabaseAnonKey) {
  throw createError('Missing Supabase environment variables');
}

/**
 * Typed Supabase client instance
 * Uses Database type to provide type safety for all database operations
 * Configured with:
 * - Auth state persistence in localStorage
 * - Automatic session refresh
 * - Session detection in URL
 */
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
    storage: localStorage,
    storageKey: 'printingtracker-auth',
    flowType: 'pkce',
    debug: true // Enable debug logging for auth
  }
});

// Log initial auth state
supabase.auth.getSession().then(({ data: { session }, error }) => {
  console.log('Initial auth state:', {
    hasSession: !!session,
    userId: session?.user?.id,
    error: error?.message,
    timestamp: new Date().toISOString()
  });
});

// Subscribe to auth state changes
supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth state changed:', {
    event,
    userId: session?.user?.id,
    timestamp: new Date().toISOString()
  });
});

// Log database connection status
supabase.from('profiles').select('count').single().then(({ data, error }) => {
  console.log('Database connection test:', {
    isConnected: !error,
    error: error?.message,
    count: data?.count,
    timestamp: new Date().toISOString()
  });
});

/**
 * Type exports for convenience
 * Tables: Type containing all database table definitions
 * Projects: Type for project table rows
 */
export type Tables = Database['public']['Tables'];
export type Projects = Tables['projects']['Row'];
