import { useState } from 'react';
import { FilamentModal } from './FilamentModal';
import { FilamentsTable } from './FilamentsTable';
import { Loading } from './shared/Loading';
import { Error } from './shared/Error';
import { useFilaments } from '../hooks/useFilaments';
import { useViewport } from '../hooks/useViewport';
import { FilamentsMobile } from './mobile/FilamentsMobile';
import { Filament } from '../types';

export function Filaments() {
  const { 
    filaments,
    rawFilaments,
    saveFilament,
    updateFilament,
    deleteFilament,
    isLoading,
    error,
    refetch
  } = useFilaments();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingFilament, setEditingFilament] = useState<Filament | undefined>(undefined);
  const { isMobile } = useViewport();

  const handleAddFilament = (filamentData: Omit<Filament, 'id' | 'created_at' | 'updated_at'>) => {
    if (editingFilament) {
      updateFilament(editingFilament.id, filamentData);
      setEditingFilament(undefined);
    } else {
      saveFilament(filamentData);
    }
    setIsModalOpen(false);
  };

  const handleEdit = (filament: Filament) => {
    setEditingFilament(filament);
    setIsModalOpen(true);
  };

  const handleDelete = (id: string) => {
    if (window.confirm('Are you sure you want to delete this filament?')) {
      deleteFilament(id);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingFilament(undefined);
  };

  if (isLoading) {
    return <Loading message="Loading filaments..." />;
  }

  if (error) {
    return <Error message={error} onRetry={refetch} />;
  }

  if (isMobile) {
    return (
      <FilamentsMobile
        filaments={filaments}
        rawFilaments={rawFilaments}
        onEdit={handleEdit}
        onDelete={handleDelete}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editingFilament={editingFilament}
        onAddFilament={handleAddFilament}
        onCloseModal={handleCloseModal}
        isLoading={isLoading}
        error={error}
      />
    );
  }

  return (
    <div className="filaments-container">
      <div className="filaments-header">
        <h2>Filament Inventory</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="add-filament-btn"
          aria-label="Add new filament"
        >
          + New Filament
        </button>
      </div>
      
      <FilamentsTable
        filaments={filaments}
        rawFilaments={rawFilaments}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <FilamentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleAddFilament}
        editingFilament={editingFilament}
        isEditing={!!editingFilament}
      />
    </div>
  );
}
