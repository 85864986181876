import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthContext } from './useAuthContext';

interface TaxRates {
  federal: number;
  state: number;
  local: number;
}

const DEFAULT_TAX_RATES: TaxRates = {
  federal: 21,
  state: 5,
  local: 1
};

export function useTaxRates() {
  const [taxRates, setTaxRates] = useState<TaxRates>(DEFAULT_TAX_RATES);
  const [isLoading, setIsLoading] = useState(true);
  const auth = useAuthContext();

  const loadTaxRates = useCallback(async () => {
    if (!auth.user?.id) {
      setTaxRates(DEFAULT_TAX_RATES);
      setIsLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('tax_rates')
        .select('*')
        .eq('user_id', auth.user.id)
        .single();

      if (error) throw error;

      if (data) {
        setTaxRates({
          federal: data.federal,
          state: data.state,
          local: data.local
        });
      } else {
        // Create default tax rates if none exist
        const { error: insertError } = await supabase
          .from('tax_rates')
          .insert([{
            ...DEFAULT_TAX_RATES,
            user_id: auth.user.id
          }]);

        if (insertError) throw insertError;
        setTaxRates(DEFAULT_TAX_RATES);
      }
    } catch (error) {
      console.error('Error loading tax rates:', error);
      setTaxRates(DEFAULT_TAX_RATES);
    } finally {
      setIsLoading(false);
    }
  }, [auth.user?.id]);

  const updateTaxRates = async (newRates: TaxRates) => {
    if (!auth.user?.id) {
      throw new Error('User must be authenticated to update tax rates');
    }

    try {
      const { error: updateError } = await supabase
        .from('tax_rates')
        .update({
          federal: newRates.federal,
          state: newRates.state,
          local: newRates.local
        })
        .eq('user_id', auth.user.id);

      if (updateError) throw updateError;

      setTaxRates(newRates);
    } catch (error) {
      console.error('Error updating tax rates:', error);
      throw error;
    }
  };

  useEffect(() => {
    loadTaxRates();
  }, [loadTaxRates]);

  return {
    taxRates,
    isLoading,
    updateTaxRates
  };
}
