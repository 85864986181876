import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { usePrinterSettings } from '../hooks/usePrinterSettings';
import { Loading } from './shared/Loading';
import { Error } from './shared/Error';
import { ErrorModal } from './ErrorModal';
import '../styles/PrinterSettings.css';

interface PrinterSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PrinterSettings: React.FC<PrinterSettingsProps> = ({ isOpen, onClose }) => {
  const {
    printerNames,
    isLoading,
    error,
    savePrinterName,
    deletePrinterName,
    refetch
  } = usePrinterSettings();

  const [newPrinterName, setNewPrinterName] = useState('');
  const [newPrinterModel, setNewPrinterModel] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      setIsErrorModalOpen(true);
    }
  }, [error]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!newPrinterName.trim() || !newPrinterModel.trim()) {
      setErrorMessage('Printer name and model are required');
      setIsErrorModalOpen(true);
      return;
    }

    try {
      await savePrinterName({
        name: newPrinterName.trim(),
        model: newPrinterModel.trim()
      });
      setNewPrinterName('');
      setNewPrinterModel('');
    } catch (error) {
      setErrorMessage('Failed to save printer');
      setIsErrorModalOpen(true);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deletePrinterName(id);
    } catch (error) {
      setErrorMessage('Failed to delete printer');
      setIsErrorModalOpen(true);
    }
  };

  if (!isOpen) return null;

  if (isLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <Loading message="Loading printer settings..." />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <Error message={error} onRetry={refetch} />
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content printer-settings" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Printer Settings</h2>
          <button className="close-btn" onClick={onClose}>
            <MdClose size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="add-printer-form">
          <div className="form-group">
            <label htmlFor="printerName">Printer Name:</label>
            <input
              type="text"
              id="printerName"
              value={newPrinterName}
              onChange={(e) => setNewPrinterName(e.target.value)}
              placeholder="Enter printer name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="printerModel">Printer Model:</label>
            <select
              id="printerModel"
              value={newPrinterModel}
              onChange={(e) => setNewPrinterModel(e.target.value)}
            >
              <option value="">Select a model</option>
              <option value="Prusa MK3S+">Prusa MK3S+</option>
              <option value="Prusa Mini+">Prusa Mini+</option>
              <option value="Prusa XL">Prusa XL</option>
              <option value="Bambu Lab X1C">Bambu Lab X1C</option>
              <option value="Bambu Lab P1P">Bambu Lab P1P</option>
              <option value="Bambu Lab X1">Bambu Lab X1</option>
              <option value="Voron 2.4">Voron 2.4</option>
              <option value="Voron Trident">Voron Trident</option>
              <option value="Voron 0.1">Voron 0.1</option>
              <option value="Ender 3">Ender 3</option>
              <option value="Ender 3 V2">Ender 3 V2</option>
              <option value="Ender 3 Pro">Ender 3 Pro</option>
              <option value="Ender 3 S1">Ender 3 S1</option>
              <option value="Ender 5">Ender 5</option>
              <option value="Ender 5 Plus">Ender 5 Plus</option>
              <option value="Ender 5 Pro">Ender 5 Pro</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <button type="submit" className="add-btn">
            Add Printer
          </button>
        </form>

        <div className="printer-list">
          <h3>Current Printers</h3>
          {printerNames.length === 0 ? (
            <p className="no-printers">No printers added yet.</p>
          ) : (
            <ul>
              {printerNames.map((printer) => (
                <li key={printer.id} className="printer-item">
                  <div className="printer-info">
                    <span className="printer-name">{printer.name}</span>
                    <span className="printer-model">{printer.model}</span>
                  </div>
                  <button
                    onClick={() => handleDelete(printer.id)}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};
