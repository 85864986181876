import { MainLayout } from '../layouts/MainLayout';
import { Filaments } from '../components/Filaments';
import { ErrorBoundary } from '../components/ErrorBoundary';

export function FilamentsPage() {
  return (
    <MainLayout>
      <ErrorBoundary>
        <Filaments />
      </ErrorBoundary>
    </MainLayout>
  );
}
