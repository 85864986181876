/**
 * PrinterMetrics Component
 * Displays detailed metrics for each 3D printer in the system, including print times,
 * operational status, and usage statistics. Supports pagination for managing large
 * numbers of printers.
 */
import { MouseEvent, useState, useEffect } from 'react';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Metrics, PrinterMetric } from '../types';
import { formatTime, formatPercentage } from '../utils/formatting';
import { usePrinterSettings } from '../hooks/usePrinterSettings';
import { supabase } from '../lib/supabase';
import '../styles/PrinterMetrics.css';

/**
 * Props interface for PrinterMetrics component
 * @property {Metrics} metrics - Printer usage and performance metrics
 * @property {boolean} isCollapsed - Whether the metrics section is collapsed
 * @property {Function} onToggleCollapse - Handler for toggling section collapse
 */
interface PrinterMetricsProps {
  metrics: Metrics;
  isCollapsed: boolean;
  onToggleCollapse: (e: MouseEvent) => void;
}

/** Number of printers to display per page */
const PRINTERS_PER_PAGE = 12;

/**
 * PrinterMetrics Component
 * Displays a grid of printer cards showing print time statistics and operational status
 * for each printer in the system. Includes pagination for managing large printer fleets.
 */
export function PrinterMetrics({ metrics, isCollapsed, onToggleCollapse }: PrinterMetricsProps) {
  const { togglePrinterOutOfOrder } = usePrinterSettings();
  const [currentPage, setCurrentPage] = useState(1);
  const [printers, setPrinters] = useState<any[]>([]);

  useEffect(() => {
    const loadPrinters = async () => {
      const { data } = await supabase
        .from('printers')
        .select('*')
        .order('id', { ascending: true });
      
      if (data) {
        setPrinters(data);
      }
    };
    loadPrinters();
  }, []);

  /**
   * Creates an array of all printers with their metrics
   * Combines configured printer settings with their corresponding metrics
   * If no metrics exist for a printer, creates default empty metrics
   */
  const allPrinters = printers.map(printer => {
    const id = printer.id.toString();
    const metricData = metrics.printerMetrics[id] || {
      time: 0,
      name: printer.name || `Printer ${printer.id}`,
      model: printer.model,
      projects: 0,
      printTime: 0,
      materialCost: 0,
      revenue: 0
    };
    return [id, metricData] as [string, PrinterMetric];
  });

  // Pagination calculations
  const totalPages = Math.ceil(allPrinters.length / PRINTERS_PER_PAGE);
  const startIndex = (currentPage - 1) * PRINTERS_PER_PAGE;
  const endIndex = Math.min(startIndex + PRINTERS_PER_PAGE, allPrinters.length);
  const currentPrinters = allPrinters.slice(startIndex, endIndex);

  // Calculate total print time across all printers
  const totalPrintTime = metrics.totalPrintTime;

  /**
   * Handles toggling a printer's out-of-order status
   * Prevents event propagation to avoid triggering section collapse
   */
  const handleOutOfOrderToggle = (e: React.ChangeEvent<HTMLInputElement>, printerId: number) => {
    e.stopPropagation();
    togglePrinterOutOfOrder(printerId);
  };

  /**
   * Handles pagination navigation
   * Ensures page number stays within valid range
   */
  const handlePageChange = (newPage: number) => {
    setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
  };

  return (
    <div className="printer-metrics">
      {/* Section header with collapse toggle and printer count */}
      <div className="section-header" onClick={onToggleCollapse}>
        <FaChevronDown className={`collapse-icon ${isCollapsed ? 'collapsed' : ''}`} />
        <h3>
          Print Time by Printer ({startIndex + 1}-{endIndex} of {allPrinters.length})
          {totalPrintTime > 0 && (
            <span className="total-time">
              Total: {formatTime(totalPrintTime)}
            </span>
          )}
        </h3>
      </div>

      {/* Main content section - collapsible */}
      <div className={`section-content ${isCollapsed ? 'collapsed' : ''}`}>
        {/* Grid of printer metric cards */}
        <div className="printer-time-cards">
          {currentPrinters.map(([id, data]) => {
            const printTime = data.printTime || 0;
            const percentage = formatPercentage(printTime, totalPrintTime);
            const printerConfig = printers.find(p => p.id === parseInt(id));
            const isOutOfOrder = printerConfig?.out_of_order || false;
            const customName = data.name !== `Printer ${id}` ? data.name : null;

            return (
              <div 
                key={id} 
                className={`printer-time-card ${isOutOfOrder ? 'out-of-order' : ''}`} 
                data-printer-id={id}
              >
                {/* Printer identification and status */}
                <div className="printer-header">
                  <h4>Printer {id}</h4>
                  <label className="out-of-order-toggle">
                    <input
                      type="checkbox"
                      checked={isOutOfOrder}
                      onChange={(e) => handleOutOfOrderToggle(e, parseInt(id))}
                      onClick={(e) => e.stopPropagation()}
                    />
                    Out of Order
                  </label>
                </div>
                {customName && <div className="printer-name">{customName}</div>}
                <div className="printer-model">{data.model}</div>
                {/* Print time statistics */}
                <div className="time">
                  {formatTime(printTime)}
                  <div className="time-percentage">
                    {percentage}% of total
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Pagination controls - only shown if multiple pages exist */}
        {totalPages > 1 && (
          <div className="pagination-controls">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              <FaChevronLeft />
            </button>
            <span className="page-info">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
