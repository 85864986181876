/**
 * Error Handling Utilities
 * Helper functions for consistent error handling across the application
 */

/**
 * Formats an error into a user-friendly message
 * @param error - The error to format
 * @returns A string message suitable for display to users
 */
export function formatError(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }
  return 'An unexpected error occurred';
}

/**
 * Creates a new Error with a formatted message
 * @param error - The original error or message
 * @returns A new Error with a formatted message
 */
export function createError(error: unknown): Error {
  return new Error(formatError(error));
}
