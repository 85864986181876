/**
 * useViewport Hook
 * Custom hook for handling responsive design through viewport size detection.
 * Provides a simple way to determine if the current viewport is mobile-sized,
 * enabling responsive behavior throughout the application.
 */
import { useState, useEffect } from 'react';

/**
 * Breakpoint width in pixels that determines mobile viewport
 * Viewports narrower than this are considered mobile
 */
const MOBILE_BREAKPOINT = 768;

/**
 * useViewport Hook
 * Tracks viewport size changes and provides mobile/desktop state.
 * Features:
 * - Automatic viewport width detection
 * - Real-time updates on window resize
 * - Cleanup of event listeners on unmount
 * 
 * @returns {Object} Object containing isMobile boolean state
 */
export function useViewport() {
  // Track if viewport is mobile-sized
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    /**
     * Updates isMobile state based on window width
     * Compares current window width against MOBILE_BREAKPOINT
     */
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    // Set initial value on mount
    handleWindowResize();

    // Add resize event listener
    window.addEventListener('resize', handleWindowResize);
    
    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { isMobile };
}
