import type { FC } from 'react';
import { useProjects } from '../hooks/useProjects';
import { Loading } from '../components/shared/Loading';
import { Error } from '../components/shared/Error';
import ProjectsTable from '../components/ProjectsTable';
import { Project, ProjectStatus } from '../types';

export const CalendarPage: FC = () => {
  const {
    projects,
    isLoading,
    error,
    updateProjectStatus,
    saveProject,
    deleteProject,
    refetch
  } = useProjects();

  const handleStatusChange = async (id: string, newStatus: ProjectStatus) => {
    try {
      await updateProjectStatus(id, newStatus);
    } catch (error) {
      console.error('Error updating project status:', error);
    }
  };

  const handleEdit = async (project: Project) => {
    try {
      await saveProject(project);
    } catch (error) {
      console.error('Error editing project:', error);
    }
  };

  const handleDelete = async (project: Project) => {
    try {
      await deleteProject(project.id);
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleDuplicate = async (project: Project) => {
    try {
      const { id, created_at, ...projectData } = project;
      await saveProject({
        ...projectData,
        material_cost: projectData.material_cost || 0
      });
    } catch (error) {
      console.error('Error duplicating project:', error);
    }
  };

  if (isLoading) {
    return <Loading message="Loading calendar..." />;
  }

  if (error) {
    return <Error message={error} onRetry={refetch} />;
  }

  return (
    <div className="calendar-page">
      <ProjectsTable
        projects={projects}
        onStatusChange={handleStatusChange}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
      />
    </div>
  );
};
