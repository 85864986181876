/**
 * Protected Route Component
 * Wraps routes that require authentication, redirecting to login if user is not authenticated
 */
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Loading } from '../shared/Loading';

/**
 * ProtectedRoute Component
 * Ensures routes are only accessible to authenticated users
 */
export function ProtectedRoute() {
  const auth = useAuthContext();
  const location = useLocation();

  // Show loading state while checking authentication
  if (auth.loading) {
    return <Loading message="Loading authentication..." />;
  }

  // Redirect to login if not authenticated, preserving the intended destination
  if (!auth.user) {
    console.log('No authenticated user, redirecting to auth');
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  console.log('User authenticated, rendering protected content');
  // Render protected content if authenticated
  return <Outlet />;
}
