import React, { useEffect } from 'react';
import { MdDone } from 'react-icons/md';

interface ToastProps {
  message: string;
  onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="toast-overlay">
      <div className="toast-content">
        <div className="toast-icon">
          <MdDone size={20} />
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
};
