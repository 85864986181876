/**
 * Authentication Context Types and Creation
 * Defines types and creates the auth context
 */
import { createContext } from 'react';
import { User, AuthError } from '@supabase/supabase-js';

export interface UserProfile {
  id: string;
  username: string;
  email: string;
  created_at: string;
}

export interface AuthContextValue {
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
  signUp: (data: { email: string; password: string; username: string }) => Promise<{ error: AuthError | null }>;
  signIn: (data: { email: string; password: string }) => Promise<{ error: AuthError | null }>;
  signOut: () => Promise<{ error: AuthError | null }>;
  resetPassword: (email: string) => Promise<{ error: AuthError | null }>;
  updateProfile: (updates: Partial<UserProfile>) => Promise<{ error: AuthError | null }>;
}

// Create context with a default value that matches the shape of AuthContextValue
export const AuthContext = createContext<AuthContextValue | null>(null);
