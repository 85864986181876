/**
 * Printer Utilities
 * Collection of utilities for managing printer configurations, formatting,
 * and handling printer-related operations throughout the application.
 */
import { PrinterConfig } from '../types';
import { supabase } from '../lib/supabase';

/**
 * Interface for printer selection options
 * Used in dropdown menus and selection components
 */
export interface PrinterOption {
  value: string;  // Unique identifier/value for the printer
  label: string;  // Display text for the printer
}

/**
 * Custom error class for printer formatting errors
 * Used when printer type strings don't match expected format
 */
export class PrinterFormatError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PrinterFormatError';
  }
}

export const printerUtils = {
  /**
   * Formats printer name and model into a standardized string
   * Removes any existing parenthetical content from name before formatting
   * 
   * @param {string} name - Printer name
   * @param {string} model - Printer model
   * @returns {string} Formatted printer type string (e.g., "Printer 1 (Ender 3)")
   * 
   * @example
   * formatPrinterType("Printer 1", "Ender 3") // Returns "Printer 1 (Ender 3)"
   * formatPrinterType("Printer 1 (Old)", "Ender 3") // Returns "Printer 1 (Ender 3)"
   */
  formatPrinterType: (name: string, model: string): string => {
    const cleanName = name.split('(')[0].trim();
    return `${cleanName} (${model})`;
  },

  /**
   * Parses a formatted printer type string into name and model components
   * 
   * @param {string} printerType - Formatted printer type string
   * @returns {Object | null} Object containing name and model, or null if parsing fails
   * 
   * @example
   * parsePrinterType("Printer 1 (Ender 3)") // Returns { name: "Printer 1", model: "Ender 3" }
   * parsePrinterType("Invalid Format") // Returns null
   */
  parsePrinterType: (printerType: string): { name: string; model: string } | null => {
    try {
      const match = printerType.match(/^(.+?)\s*\((.+?)\)$/);
      if (!match) {
        throw new PrinterFormatError(`Invalid printer type format: ${printerType}`);
      }
      return {
        name: match[1].trim(),
        model: match[2].trim()
      };
    } catch (error) {
      console.error('Error parsing printer type:', error);
      return null;
    }
  },

  /**
   * Gets printer options for selection components
   * Retrieves printer configurations and formats them as selection options
   * Falls back to default option if no configurations are found
   * 
   * @returns {Promise<PrinterOption[]>} Array of printer options for selection
   * 
   * @example
   * getPrinterOptions() // Returns [{ value: "Printer 1 (Ender 3)", label: "Printer 1 (Ender 3)" }, ...]
   */
  getPrinterOptions: async (): Promise<PrinterOption[]> => {
    try {
      const { data: printerConfigs, error } = await supabase
        .from('printers')
        .select('*')
        .order('id', { ascending: true });

      if (error) throw error;

      if (!printerConfigs?.length) {
        return [{
          value: 'Printer 1 (Other)',
          label: 'Printer 1 (Other)'
        }];
      }

      return printerConfigs.map(config => {
        const formattedType = printerUtils.formatPrinterType(config.name, config.model);
        return {
          value: formattedType,
          label: formattedType
        };
      });
    } catch (error) {
      console.error('Error getting printer options:', error);
      return [{
        value: 'Printer 1 (Other)',
        label: 'Printer 1 (Other)'
      }];
    }
  },

  /**
   * Finds matching printer configuration for a given printer type string
   * Parses the printer type and searches configurations for a match
   * 
   * @param {string} printerType - Formatted printer type string
   * @param {PrinterConfig[]} configs - Array of printer configurations to search
   * @returns {PrinterConfig | null} Matching printer configuration or null if not found
   * 
   * @example
   * findMatchingConfig("Printer 1 (Ender 3)", configs) // Returns matching PrinterConfig or null
   */
  findMatchingConfig: (printerType: string, configs: PrinterConfig[]): PrinterConfig | null => {
    const parsed = printerUtils.parsePrinterType(printerType);
    if (!parsed) return null;

    return configs.find(config => 
      config.name.includes(parsed.name) && config.model === parsed.model
    ) || null;
  }
};
