/**
 * SignIn Component
 * Handles user authentication through email/password login
 */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ResetPasswordModal } from './ResetPasswordModal';
import { NotificationModal } from './NotificationModal';
import './Auth.css';

interface SignInProps {
  onToggleForm: () => void;
}

export function SignIn({ onToggleForm }: SignInProps) {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [resetError, setResetError] = useState<string | null>(null);
  const [resetLoading, setResetLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      console.log('Attempting sign in for:', email);
      const { error } = await auth.signIn({ email, password });
      if (error) throw error;
      
      // If successful, navigate to home
      console.log('Sign in successful, navigating to home');
      navigate('/', { replace: true });
    } catch (err) {
      console.error('Sign in error:', err);
      setError(err instanceof Error ? err.message : 'Failed to sign in');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (email: string) => {
    setResetError(null);
    setResetLoading(true);

    try {
      console.log('Sending password reset email to:', email);
      const { error } = await auth.resetPassword(email);
      if (error) throw error;
      
      // Show success notification and close reset modal
      console.log('Password reset email sent successfully');
      setShowResetModal(false);
      setShowNotificationModal(true);
    } catch (err) {
      console.error('Reset password error:', err);
      setResetError(err instanceof Error ? err.message : 'Failed to send reset email');
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <>
      <div className="auth-container">
        <div className="auth-card">
          <h2>Sign In</h2>
          {error && <div className="auth-error">{error}</div>}
          <form onSubmit={handleSubmit} className="auth-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
                disabled={loading}
                autoComplete="email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your password"
                disabled={loading}
                autoComplete="current-password"
              />
              <button
                type="button"
                onClick={() => setShowResetModal(true)}
                className="text-sm text-blue-600 hover:text-blue-800 mt-1 text-left"
                disabled={loading}
              >
                Forgot Password?
              </button>
            </div>
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Signing in...' : 'Sign In'}
            </button>
          </form>
          <p className="auth-toggle">
            Don't have an account?{' '}
            <button onClick={onToggleForm} className="auth-link" disabled={loading}>
              Sign Up
            </button>
          </p>
        </div>
      </div>

      <ResetPasswordModal
        isOpen={showResetModal}
        onClose={() => setShowResetModal(false)}
        onSubmit={handleResetPassword}
        loading={resetLoading}
        error={resetError}
      />

      <NotificationModal
        isOpen={showNotificationModal}
        onClose={() => setShowNotificationModal(false)}
        title="Check Your Email"
        message="We've sent you instructions to reset your password. Please check your email inbox."
        type="info"
      />
    </>
  );
}
