import React from 'react';
import { Filament, ProjectFilament } from '../../types';
import { formatCurrency, formatWeight } from '../../utils/formatting';

interface FilamentRowProps {
  projectFilament: ProjectFilament;
  filament: Filament | undefined;
  displayId: string;
  onCopyId: (id: string) => void;
}

export const FilamentRow: React.FC<FilamentRowProps> = ({
  projectFilament,
  filament,
  displayId,
  onCopyId
}) => {
  const totalCost = (projectFilament.weight / 1000) * (projectFilament.cost || 0);

  return (
    <tr>
      <td 
        className="filament-id clickable" 
        onClick={() => onCopyId(displayId)}
        title="Click to copy ID"
      >
        {displayId}
      </td>
      <td>{filament?.brand || 'Unknown'}</td>
      <td>{filament?.type || 'Unknown'}</td>
      <td>{filament?.color || 'Unknown'}</td>
      <td>{filament?.td || 'N/A'}</td>
      <td>{formatWeight(projectFilament.weight || 0)}</td>
      <td>{formatCurrency(projectFilament.cost || 0)}</td>
      <td>{formatCurrency(totalCost)}</td>
    </tr>
  );
};
