/**
 * FilamentsTable Component
 * Displays a table of 3D printer filaments with their properties, stock levels,
 * usage statistics, and management actions. Tracks both raw and current quantities
 * to show usage statistics.
 * 
 * Key Features:
 * - Simple sequential IDs for easy labeling (01, 02, etc.)
 * - Detailed filament properties (brand, type, color)
 * - Stock tracking with usage calculations
 * - Cost information
 * - Edit and delete actions
 */
import { Filament } from '../types';

/**
 * Props interface for FilamentsTable component
 * @property {Filament[]} filaments - Current filament inventory data with adjusted quantities
 * @property {Filament[]} rawFilaments - Original filament data for usage calculations
 * @property {Function} onEdit - Handler for editing a filament entry
 * @property {Function} onDelete - Handler for deleting a filament entry
 */
interface FilamentsTableProps {
  filaments: Filament[];
  rawFilaments: Filament[];
  onEdit: (filament: Filament) => void;
  onDelete: (id: string) => void;
}

/**
 * FilamentsTable Component
 * Renders a detailed table of filament inventory including:
 * - Simple sequential IDs for easy reference and labeling
 * - Filament properties (brand, type, color)
 * - Technical details (TD - Temperature Dependent properties)
 * - Stock levels and usage tracking
 * - Cost information
 * - Management actions
 */
export function FilamentsTable({
  filaments,
  rawFilaments,
  onEdit,
  onDelete
}: FilamentsTableProps) {
  return (
    <div className="filaments-table-container">
      <table className="filaments-table" role="grid">
        {/* Table header defining columns */}
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Brand</th>
            <th scope="col">Type</th>
            <th scope="col">Color</th>
            <th scope="col">TD</th>
            <th scope="col">Stock (1kg rolls)</th>
            <th scope="col">Used</th>
            <th scope="col">Available</th>
            <th scope="col">Cost/kg</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        
        {/* Table body with filament data */}
        <tbody>
          {filaments.map((filament, index) => {
            // Find the raw filament data to calculate usage
            const rawFilament = rawFilaments.find(f => f.id === filament.id);

            // Calculate usage statistics
            // Used weight = original quantity - current quantity
            const usedWeight = (rawFilament?.quantity || 0) - filament.quantity;
            const usedKg = usedWeight.toFixed(3);
            const availableKg = filament.quantity.toFixed(2);

            // Generate a simple sequential ID for easy reference
            // Format: 01, 02, 03, etc.
            // This makes it easy for users to write on labels while maintaining
            // the internal database ID for operations
            const displayId = (index + 1).toString().padStart(2, '0');

            return (
              <tr key={filament.id}>
                {/* Display the simple sequential ID instead of database ID */}
                <td className="filament-id">{displayId}</td>
                <td>{filament.brand}</td>
                <td>{filament.type}</td>
                <td>{filament.color}</td>
                <td>{filament.td || 'N/A'}</td>
                <td>{rawFilament?.quantity}</td>
                <td>{usedKg}kg</td>
                <td>{availableKg}kg</td>
                <td>${filament.cost.toFixed(2)}</td>
                {/* Action buttons with ARIA labels for accessibility */}
                <td className="actions">
                  <button
                    onClick={() => onEdit(filament)}
                    className="edit-btn"
                    aria-label={`Edit ${filament.brand} ${filament.color}`}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => onDelete(filament.id)}
                    className="delete-btn"
                    aria-label={`Delete ${filament.brand} ${filament.color}`}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
          {/* Empty state message when no filaments exist */}
          {filaments.length === 0 && (
            <tr>
              <td colSpan={10} className="no-filaments">
                No filaments added yet. Click "New Filament" to add one.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
