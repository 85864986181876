import type { FC } from 'react';
import { useState } from 'react';
import { useProjects } from '../hooks/useProjects';
import { BusinessOverview } from '../components/BusinessOverview';
import { ProjectModal } from '../components/ProjectModal';
import { Loading } from '../components/shared/Loading';
import { Error } from '../components/shared/Error';
import { Project, ProjectStatus } from '../types';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { ErrorModal } from '../components/ErrorModal';
import ProjectsTable from '../components/ProjectsTable';

export const MainPage: FC = () => {
  const {
    projects,
    isLoading,
    error,
    saveProject,
    deleteProject,
    updateProjectStatus,
    refetch
  } = useProjects();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleStatusChange = async (id: string, newStatus: ProjectStatus) => {
    try {
      await updateProjectStatus(id, newStatus);
    } catch (error) {
      console.error('Error updating project status:', error);
      setErrorMessage('Failed to update project status');
      setIsErrorModalOpen(true);
    }
  };

  const handleEdit = (project: Project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleDelete = (project: Project) => {
    setSelectedProject(project);
    setIsDeleteModalOpen(true);
  };

  const handleDuplicate = async (project: Project) => {
    try {
      const { id, created_at, ...projectData } = project;
      await saveProject({
        ...projectData,
        material_cost: projectData.material_cost || 0
      });
    } catch (error) {
      console.error('Error duplicating project:', error);
      setErrorMessage('Failed to duplicate project');
      setIsErrorModalOpen(true);
    }
  };

  const handleConfirmDelete = async () => {
    if (!selectedProject) return;

    try {
      await deleteProject(selectedProject.id);
      setIsDeleteModalOpen(false);
      setSelectedProject(null);
    } catch (error) {
      console.error('Error deleting project:', error);
      setErrorMessage('Failed to delete project');
      setIsErrorModalOpen(true);
    }
  };

  if (isLoading) {
    return <Loading message="Loading projects..." />;
  }

  if (error) {
    return <Error message={error} onRetry={refetch} />;
  }

  return (
    <div className="main-page">
      <BusinessOverview projects={projects} />
      <ProjectsTable
        projects={projects}
        onStatusChange={handleStatusChange}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
      />
      <ProjectModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedProject(null);
        }}
        onSubmit={async (data) => {
          const projectData = {
            ...data,
            material_cost: 0,
            filaments: []
          };
          await saveProject(projectData);
          setIsModalOpen(false);
          setSelectedProject(null);
        }}
        editingProject={selectedProject || undefined}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setSelectedProject(null);
        }}
        onConfirm={handleConfirmDelete}
        itemToDelete={selectedProject?.name || ''}
        itemType="project"
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
    </div>
  );
};
