import { Dispatch, SetStateAction, useState } from 'react';
import { MdEdit, MdDelete, MdExpandMore, MdExpandLess } from 'react-icons/md';
import { FilamentModal } from '../FilamentModal';
import { Error } from '../shared/Error';
import { Loading } from '../shared/Loading';
import { Filament } from '../../types';
import '../../styles/FilamentsMobile.css';

interface FilamentsMobileProps {
  filaments: Filament[];
  rawFilaments: Filament[];
  onEdit: (filament: Filament) => void;
  onDelete: (id: string) => void;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  editingFilament: Filament | undefined;
  onAddFilament: (data: Omit<Filament, 'id'>) => void;
  onCloseModal: () => void;
  isLoading?: boolean;
  error?: string | null;
}

interface FilamentCardProps {
  filament: Filament;
  rawFilament: Filament | undefined;
  isExpanded: boolean;
  onToggle: (id: string, e: React.MouseEvent) => void;
  onEdit: (filament: Filament) => void;
  onDelete: (id: string) => void;
}

function FilamentCard({
  filament,
  rawFilament,
  isExpanded,
  onToggle,
  onEdit,
  onDelete
}: FilamentCardProps) {
  const usedWeight = (rawFilament?.quantity || 0) - filament.quantity;
  const usedKg = usedWeight.toFixed(3);
  const availableKg = filament.quantity.toFixed(2);
  const shortId = filament.id.split('_').pop()?.toUpperCase() || '';

  return (
    <div className="filament-card">
      <div className="filament-card-header">
        <div 
          className="filament-title-row"
          onClick={(e) => onToggle(filament.id, e)}
        >
          <div className="expand-icon">
            {isExpanded ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
          </div>
          <div className="filament-id-brand">
            <span className="filament-id">{shortId}</span>
            <h3>{filament.brand}</h3>
          </div>
        </div>
        <div className="filament-info-row">
          <span className="filament-color">{filament.color}</span>
          <div className="filament-actions">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(filament);
              }}
              className="icon-btn edit-btn"
              aria-label="Edit filament"
            >
              <MdEdit size={24} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(filament.id);
              }}
              className="icon-btn delete-btn"
              aria-label="Delete filament"
            >
              <MdDelete size={24} />
            </button>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="filament-details">
          <div className="detail-row">
            <span className="label">Type:</span>
            <span className="value">{filament.type}</span>
          </div>
          <div className="detail-row">
            <span className="label">TD:</span>
            <span className="value">{filament.td || 'N/A'}</span>
          </div>
          <div className="detail-row">
            <span className="label">Stock:</span>
            <span className="value">{rawFilament?.quantity} rolls</span>
          </div>
          <div className="detail-row">
            <span className="label">Used:</span>
            <span className="value">{usedKg}kg</span>
          </div>
          <div className="detail-row">
            <span className="label">Available:</span>
            <span className="value">{availableKg}kg</span>
          </div>
          <div className="detail-row">
            <span className="label">Cost/kg:</span>
            <span className="value">${filament.cost.toFixed(2)}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export function FilamentsMobile({
  filaments,
  rawFilaments,
  onEdit,
  onDelete,
  isModalOpen,
  setIsModalOpen,
  editingFilament,
  onAddFilament,
  onCloseModal,
  isLoading,
  error
}: FilamentsMobileProps) {
  const [expandedFilaments, setExpandedFilaments] = useState<Set<string>>(new Set());

  const toggleFilament = (filamentId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const newExpandedFilaments = new Set(expandedFilaments);
    if (expandedFilaments.has(filamentId)) {
      newExpandedFilaments.delete(filamentId);
    } else {
      newExpandedFilaments.add(filamentId);
    }
    setExpandedFilaments(newExpandedFilaments);
  };

  if (isLoading) {
    return <Loading message="Loading filaments..." />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return (
    <div className="filaments-mobile">
      <div className="filaments-header">
        <h2>Filament Inventory</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="add-filament-btn"
        >
          + New Filament
        </button>
      </div>
      
      <div className="filaments-list">
        {filaments.map((filament) => (
          <FilamentCard
            key={filament.id}
            filament={filament}
            rawFilament={rawFilaments.find(f => f.id === filament.id)}
            isExpanded={expandedFilaments.has(filament.id)}
            onToggle={toggleFilament}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
        {filaments.length === 0 && (
          <div className="no-filaments">
            No filaments added yet. Click "New Filament" to add one.
          </div>
        )}
      </div>

      <FilamentModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onSubmit={onAddFilament}
        editingFilament={editingFilament}
        isEditing={!!editingFilament}
      />
    </div>
  );
}
