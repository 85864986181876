/**
 * Authentication Provider Component
 * Provides authentication state and functions throughout the app
 */
import React, { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { AuthContext } from './auth-context';
import { Loading } from '../components/shared/Loading';

interface AuthProviderProps {
  children: React.ReactNode;
}

/**
 * AuthProvider Component
 * Wraps the app and provides authentication context
 */
export function AuthProvider({ children }: AuthProviderProps) {
  const auth = useAuth();

  // Add debug logging for auth state changes
  useEffect(() => {
    console.log('Auth state changed:', {
      isAuthenticated: !!auth.user,
      userId: auth.user?.id,
      profileId: auth.profile?.id,
      loading: auth.loading,
      timestamp: new Date().toISOString()
    });
  }, [auth.user, auth.profile, auth.loading]);

  // Log initial auth state
  console.log('AuthProvider current state:', {
    user: auth.user?.id,
    profile: auth.profile?.username,
    loading: auth.loading,
    hasUser: !!auth.user,
    hasProfile: !!auth.profile,
    timestamp: new Date().toISOString()
  });

  return (
    <AuthContext.Provider value={auth}>
      {auth.loading ? <Loading message="Loading authentication..." /> : children}
    </AuthContext.Provider>
  );
}
