import { useState, useEffect, FormEvent } from 'react';
import { Project, ProjectStatus, PROJECT_STATUSES, ProjectFilament } from '../types';
import { printerUtils } from '../utils/printerUtils';
import { useFilaments } from '../hooks/useFilaments';
import { usePrinterSettings } from '../hooks/usePrinterSettings';
import '../styles/ProjectModal.css';

type ProjectFormData = Omit<Project, 'id' | 'created_at' | 'material_cost'>;

interface ProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (projectData: ProjectFormData) => void;
  editingProject?: Project;
}

const DEFAULT_PROJECT_DATA: ProjectFormData = {
  name: '',
  client: '',
  print_time: 0,
  selling_price: 0,
  status: 'pending',
  printer: '',
  filaments: [],
  user_id: ''
};

class ProjectModalError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ProjectModalError';
  }
}

const outOfOrderStyle = {
  backgroundColor: '#ffebee',
  color: '#d32f2f',
  fontStyle: 'italic'
} as const;

export function ProjectModal({ isOpen, onClose, onSubmit, editingProject }: ProjectModalProps) {
  const [projectData, setProjectData] = useState<ProjectFormData>(DEFAULT_PROJECT_DATA);
  const [previousStatus, setPreviousStatus] = useState<ProjectStatus | null>(null);
  const { printerNames: printerConfigs } = usePrinterSettings();
  const { filaments, updateFilament } = useFilaments();
  const [currentFilamentId, setCurrentFilamentId] = useState<string | null>(null);
  const [currentFilamentWeight, setCurrentFilamentWeight] = useState<number>(0);
  const [editingFilamentIndex, setEditingFilamentIndex] = useState<number | null>(null);

  // Set initial printer when modal is opened
  useEffect(() => {
    if (isOpen && !projectData.printer && !editingProject) {
      const availablePrinter = printerConfigs.find(p => !p.outOfOrder);
      if (availablePrinter) {
        setProjectData(prev => ({
          ...prev,
          printer: printerUtils.formatPrinterType(availablePrinter.name, availablePrinter.model)
        }));
      }
    }
  }, [isOpen, printerConfigs]);

  // Update printer selection if current printer becomes out of order
  useEffect(() => {
    if (!isOpen || !projectData.printer) return;

    const currentPrinter = printerConfigs.find(p => 
      printerUtils.formatPrinterType(p.name, p.model) === projectData.printer
    );
    
    if (currentPrinter?.outOfOrder) {
      const availablePrinter = printerConfigs.find(p => !p.outOfOrder);
      if (availablePrinter) {
        setProjectData(prev => ({
          ...prev,
          printer: printerUtils.formatPrinterType(availablePrinter.name, availablePrinter.model)
        }));
      }
    }
  }, [isOpen, projectData.printer, printerConfigs]);

  // Set initial project data when editing
  useEffect(() => {
    if (editingProject) {
      const { id, created_at, material_cost, ...editData } = editingProject;
      // Round filament weights to 3 decimal places
      const roundedFilaments = editData.filaments.map(f => ({
        ...f,
        weight: parseFloat(f.weight.toFixed(3))
      }));
      setProjectData({ ...editData, filaments: roundedFilaments });
      setPreviousStatus(editData.status);
    } else {
      setProjectData(DEFAULT_PROJECT_DATA);
      setPreviousStatus(null);
    }
  }, [editingProject]);

  if (!isOpen) return null;

  // Show message if no printers are set up
  if (printerConfigs.length === 0) {
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <h2>Cannot Create Project</h2>
          <p>Please set up at least one printer in the settings before creating projects.</p>
          <div className="modal-actions">
            <button onClick={onClose} className="cancel-btn">Close</button>
          </div>
        </div>
      </div>
    );
  }

  // Show message if no filaments are available
  if (filaments.length === 0) {
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <h2>Cannot Create Project</h2>
          <p>Please add at least one filament before creating projects.</p>
          <div className="modal-actions">
            <button onClick={onClose} className="cancel-btn">Close</button>
          </div>
        </div>
      </div>
    );
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    try {
      const formattedData: ProjectFormData = {
        ...projectData,
        print_time: Number(projectData.print_time),
        selling_price: Number(projectData.selling_price),
        // Round all filament weights to 3 decimal places
        filaments: projectData.filaments.map(f => ({
          ...f,
          weight: parseFloat(f.weight.toFixed(3))
        }))
      };

      // Validate fields
      if (formattedData.print_time <= 0) throw new ProjectModalError('Print time must be greater than 0');
      if (formattedData.selling_price < 0) throw new ProjectModalError('Selling price cannot be negative');
      if (formattedData.filaments.length === 0) throw new ProjectModalError('Please add at least one filament');

      // Only update filament inventory when:
      // 1. New project is marked as completed/failed
      // 2. Existing project is changed to completed/failed
      // 3. Do not update if project is being changed from completed/failed to another status
      const isCompletedOrFailed = formattedData.status === 'completed' || formattedData.status === 'failed';
      const wasCompletedOrFailed = previousStatus === 'completed' || previousStatus === 'failed';

      if (isCompletedOrFailed && !wasCompletedOrFailed) {
        // Update filament inventory for each filament used
        for (const projectFilament of formattedData.filaments) {
          const filament = filaments.find(f => f.id === projectFilament.filament_id);
          if (filament) {
            const newQuantity = filament.quantity - projectFilament.weight / 1000; // Convert grams to kg
            if (newQuantity < 0) throw new ProjectModalError(`Not enough ${filament.brand} ${filament.color} filament in inventory`);

            updateFilament(filament.id, {
              ...filament,
              quantity: newQuantity
            });
          }
        }
      }

      onSubmit(formattedData);
    } catch (error) {
      console.error('Error submitting project:', error);
      throw error;
    }
  };

  const handleStatusChange = (status: string) => {
    if (PROJECT_STATUSES.includes(status as ProjectStatus)) {
      setProjectData(prev => ({ ...prev, status: status as ProjectStatus }));
    }
  };

  const handleNumberInput = (
    field: keyof ProjectFormData, 
    value: string, 
    min: number = 0
  ) => {
    try {
      const numValue = parseFloat(value) || 0;
      setProjectData(prev => ({
        ...prev,
        [field]: Math.max(min, numValue)
      }));
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      throw new ProjectModalError(`Invalid value for ${field}`);
    }
  };

  const addFilament = () => {
    if (!currentFilamentId || currentFilamentWeight <= 0) return;

    const selectedFilament = filaments.find(f => f.id === currentFilamentId);
    if (!selectedFilament) return;

    // Round the weight to 3 decimal places
    const roundedWeight = parseFloat(currentFilamentWeight.toFixed(3));

    const newFilament: ProjectFilament = {
      filament_id: currentFilamentId,
      weight: roundedWeight,
      cost: selectedFilament.cost
    };

    setProjectData(prev => ({
      ...prev,
      filaments: [...prev.filaments, newFilament]
    }));

    // Reset filament inputs
    setCurrentFilamentId(null);
    setCurrentFilamentWeight(0);
  };

  const removeFilament = (index: number) => {
    setProjectData(prev => ({
      ...prev,
      filaments: prev.filaments.filter((_, i) => i !== index)
    }));
  };

  const startEditingFilament = (index: number) => {
    setEditingFilamentIndex(index);
    const filament = projectData.filaments[index];
    setCurrentFilamentId(filament.filament_id);
    setCurrentFilamentWeight(filament.weight);
  };

  const saveEditingFilament = () => {
    if (editingFilamentIndex === null || !currentFilamentId || currentFilamentWeight <= 0) return;

    const selectedFilament = filaments.find(f => f.id === currentFilamentId);
    if (!selectedFilament) return;

    // Round the weight to 3 decimal places
    const roundedWeight = parseFloat(currentFilamentWeight.toFixed(3));

    const updatedFilament: ProjectFilament = {
      filament_id: currentFilamentId,
      weight: roundedWeight,
      cost: selectedFilament.cost
    };

    setProjectData(prev => ({
      ...prev,
      filaments: prev.filaments.map((f, i) => 
        i === editingFilamentIndex ? updatedFilament : f
      )
    }));

    // Reset editing state
    setEditingFilamentIndex(null);
    setCurrentFilamentId(null);
    setCurrentFilamentWeight(0);
  };

  const cancelEditingFilament = () => {
    setEditingFilamentIndex(null);
    setCurrentFilamentId(null);
    setCurrentFilamentWeight(0);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>{editingProject ? 'Edit Project' : 'Add New Project'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Project Name:</label>
            <input
              type="text"
              value={projectData.name}
              onChange={(e) => setProjectData({ ...projectData, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Client:</label>
            <input
              type="text"
              value={projectData.client}
              onChange={(e) => setProjectData({ ...projectData, client: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <h3>Filaments</h3>
            <div className="filament-inputs">
              <select
                value={currentFilamentId || ''}
                onChange={(e) => setCurrentFilamentId(e.target.value || null)}
              >
                <option value="">Select a filament</option>
                {filaments.map(filament => (
                  <option 
                    key={filament.id} 
                    value={filament.id}
                    disabled={filament.quantity <= 0}
                  >
                    {filament.brand} - {filament.type} - {filament.color} ({filament.quantity}kg remaining)
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Weight (g)"
                value={currentFilamentWeight || ''}
                onChange={(e) => setCurrentFilamentWeight(Number(e.target.value))}
                min="0"
                step="0.001"
              />
              {editingFilamentIndex !== null ? (
                <>
                  <button type="button" onClick={saveEditingFilament}>Save</button>
                  <button type="button" onClick={cancelEditingFilament}>Cancel</button>
                </>
              ) : (
                <button type="button" onClick={addFilament}>Add</button>
              )}
            </div>
            <div className="filament-list">
              {projectData.filaments.map((projectFilament, index) => {
                const filament = filaments.find(f => f.id === projectFilament.filament_id);
                return (
                  <div key={index} className="filament-item">
                    <div className="filament-info">
                      <span className="filament-id">ID: {projectFilament.filament_id}</span>
                      <span>
                        {filament?.brand} - {filament?.type} - {filament?.color}
                        ({projectFilament.weight.toFixed(3)}g @ ${filament?.cost}/kg)
                      </span>
                    </div>
                    <div className="filament-actions">
                      <button type="button" onClick={() => startEditingFilament(index)}>Edit</button>
                      <button type="button" onClick={() => removeFilament(index)}>Delete</button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="form-group">
            <label>Print Time (hours):</label>
            <input
              type="number"
              value={projectData.print_time || ''}
              onChange={(e) => handleNumberInput('print_time', e.target.value)}
              min="0"
              step="0.1"
              required
            />
          </div>

          <div className="form-group">
            <label>Selling Price ($):</label>
            <input
              type="number"
              value={projectData.selling_price || ''}
              onChange={(e) => handleNumberInput('selling_price', e.target.value)}
              min="0"
              step="0.01"
              required
            />
          </div>

          <div className="form-group">
            <label>Status:</label>
            <select
              value={projectData.status}
              onChange={(e) => handleStatusChange(e.target.value)}
              required
            >
              {PROJECT_STATUSES.map(status => (
                <option key={status} value={status}>{status}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Printer:</label>
            <select
              value={projectData.printer}
              onChange={(e) => setProjectData({ ...projectData, printer: e.target.value })}
              required
            >
              {printerConfigs.map(printer => {
                const printerType = printerUtils.formatPrinterType(printer.name, printer.model);
                return (
                  <option 
                    key={printer.id} 
                    value={printerType}
                    disabled={printer.outOfOrder}
                    style={printer.outOfOrder ? outOfOrderStyle : undefined}
                  >
                    {printerType} {printer.outOfOrder ? '(Out of Order)' : ''}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="modal-actions">
            <button type="button" onClick={onClose} className="cancel-btn">
              Cancel
            </button>
            <button type="submit" className="submit-btn">
              {editingProject ? 'Save Changes' : 'Create Project'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
