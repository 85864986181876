import React from 'react';

type SortField = 'name' | 'client' | 'status' | 'print_weight' | 'print_time' | 'printer' | 'cost' | 'selling_price' | 'profit';

interface TableHeaderProps {
  onSort: (field: SortField) => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ onSort }) => {
  return (
    <tr className="column-headers">
      <th style={{ width: '40px' }}></th>
      <th onClick={() => onSort('name')}>PROJECT</th>
      <th onClick={() => onSort('client')}>CLIENT</th>
      <th onClick={() => onSort('status')}>STATUS</th>
      <th onClick={() => onSort('print_weight')}>WEIGHT</th>
      <th onClick={() => onSort('print_time')}>TIME</th>
      <th onClick={() => onSort('printer')}>PRINTER</th>
      <th onClick={() => onSort('cost')}>COST</th>
      <th onClick={() => onSort('selling_price')}>PRICE</th>
      <th onClick={() => onSort('profit')}>PROFIT</th>
      <th>ACTIONS</th>
    </tr>
  );
};
