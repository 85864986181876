import React, { createContext, useContext } from 'react';
import { Filament } from '../../types';

interface FilamentContextType {
  filaments: Filament[];
  onCopyId: (id: string) => void;
  getDisplayId: (filamentId: string) => string;
}

const FilamentContext = createContext<FilamentContextType>({
  filaments: [],
  onCopyId: () => {},
  getDisplayId: (id) => id
});

interface FilamentProviderProps {
  children: React.ReactNode;
  filaments: Filament[];
  onCopyId: (id: string) => void;
  getDisplayId: (filamentId: string) => string;
}

export const FilamentProvider: React.FC<FilamentProviderProps> = ({
  children,
  filaments,
  onCopyId,
  getDisplayId
}) => {
  return (
    <FilamentContext.Provider value={{ filaments, onCopyId, getDisplayId }}>
      {children}
    </FilamentContext.Provider>
  );
};

export const useFilamentContext = () => {
  const context = useContext(FilamentContext);
  if (!context) {
    throw new Error('useFilamentContext must be used within a FilamentProvider');
  }
  return context;
};
