/**
 * Auth Component
 * Handles user authentication and registration flows
 */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { useAuthContext } from '../../hooks/useAuthContext';
import './Auth.css';

/**
 * Auth Component
 * Provides authentication interface with sign in and sign up options
 */
export function Auth() {
  const [isSignUp, setIsSignUp] = useState(false);
  const auth = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  // Get the intended destination from location state
  const from = location.state?.from?.pathname || '/';

  // Redirect to intended destination if already authenticated
  useEffect(() => {
    console.log('Auth component mounted:', {
      hasUser: !!auth.user,
      userId: auth.user?.id,
      loading: auth.loading,
      timestamp: new Date().toISOString()
    });

    if (auth.user && !auth.loading) {
      console.log('User already authenticated, redirecting to:', from);
      navigate(from, { replace: true });
    }
  }, [auth.user, auth.loading, navigate, from]);

  // Show loading state while checking authentication
  if (auth.loading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
        <p>Loading authentication...</p>
      </div>
    );
  }

  // Show auth form if not authenticated
  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div className="auth-header">
          <h1>Printing Tracker</h1>
          <p>Track your 3D printing projects and manage your business</p>
        </div>
        {isSignUp ? (
          <SignUp onToggleForm={() => setIsSignUp(false)} />
        ) : (
          <SignIn onToggleForm={() => setIsSignUp(true)} />
        )}
      </div>
    </div>
  );
}
