import { MainLayout } from '../layouts/MainLayout';
import { Tax } from '../components/Tax';
import { ErrorBoundary } from '../components/ErrorBoundary';

export function TaxPage() {
  return (
    <MainLayout>
      <ErrorBoundary>
        <Tax />
      </ErrorBoundary>
    </MainLayout>
  );
}
